import React, { useEffect, useState } from "react";

import { Toast, InfiniteScroll } from "antd-mobile";
import { getAiUsers } from "../service/api";

import { commonInterface, getAiUserListCheckedImage } from "../utils/utils";
import { useNavigate, useSearchParams } from "react-router-dom";

let index = 0;
const App: React.FC = () => {
  const [params] = useSearchParams();
  const aiUserId = params.get("aiUserId");
  const [users, setUsers] = useState<any[]>([]);
  const [userId, setUserId] = useState(aiUserId);
  const [hasMore, setHasMore] = useState(true);

  const loadMore = async () => {
    console.info(index);
    const res: any = await getAiUsers({ pageInfo: index });

    setUsers((val) => [...val, ...res.data.list]);
    index = res.data.pageInfo;
    setHasMore(res.data.pageInfo);
  };

  useEffect(() => {}, []);
  const selectHandler = (index: number) => {
    const item: any = users[index];
    setUserId(item.user.userId);
    commonInterface("selectAiUser", { user: JSON.stringify(item.user) });
    commonInterface("closePage");
  };
  return (
    <div className="bg-246 h-full flex flex-col">
      <div className="p-1.67 text-center text-1.33 font-bold text-34 relative">
        切换顾问
        <img
          onClick={() => commonInterface("closePage")}
          className="w-1.67 h-1.67 absolute right-1.33 top-1.67"
          src={require("../assets/images/del3.png")}
        />
      </div>
      <div className="px-1.33 pb-1.33 overflow-auto">
        {users.map((item: any, index) => {
          return (
            <div
              onClick={() => selectHandler(index)}
              key={index}
              className={
                "bg-white rounded-1.33 px-1 py-1.17 flex items-center justify-between" +
                (index === users.length - 1 ? "" : " mb-1.33")
              }
            >
              <div className="flex flex-row overflow-hidden">
                <img className="w-4.17 h-4.17 mr-1" src={item.user.avatar} />
                <div className="overflow-hidden">
                  <div className="text-1.25 text-34 font-bold mb-0.25">
                    {item.user.nickname}
                  </div>
                  <div className="text-1.17 text-card truncate">
                    {item.user.resume}
                  </div>
                </div>
              </div>
              {userId == item.user.userId ? (
                <img
                  className="w-1.67 h-1.67"
                  src={require("../assets/images/" +
                    getAiUserListCheckedImage())}
                />
              ) : null}
            </div>
          );
        })}
        <InfiniteScroll loadMore={loadMore} hasMore={hasMore} />
      </div>
    </div>
  );
};

export default App;
