import { commonInterface, isShowInput } from "./utils";

export const isInApp = () => {
  if (window.navigator.userAgent.match("appName/")) {
    return true;
  }
  return false;
};

export const waitFor = (delay: any) =>
  new Promise((resolve) => setTimeout(resolve, delay));
export const nativeUploadHandler = () => {
  commonInterface("uploadImage");
};
export const nativeUploadHDImageHandler = () => {
  commonInterface("uploadHDImage");
};
export const nativeUploadAvatarHandler = () => {
  isShowInput("uploadAvatar")
    ? commonInterface("uploadAvatar")
    : commonInterface("uploadImage");
};
export const getStyleBoxWidth = (len: any) => {
  switch (len) {
    case 3:
    case 4:
      return 172 + "px";
      break;
    case 5:
    case 6:
      return 264 + "px";
    default:
      return "auto";
  }
};
