import React, { MouseEventHandler } from "react";
import { getBgColor, isAndroid } from "../../utils/utils";

interface IProps {
  submitHandler: MouseEventHandler<HTMLDivElement>;
  disable: boolean;
}

const FooterUploadImage: React.FC<IProps> = (props) => {
  const { submitHandler, disable } = props;

  return (
    <div
      className={
        "pt-1 px-1.33 " +
        (isAndroid ? "pb-12" : "pb-3.83") +
        " bg-white rounded-t-1.67 shadow-up " +
        (disable ? "opacity-50" : "")
      }
      onClick={submitHandler}
    >
      <div
        className={
          "rounded-2.33 p-1.17 text-1.67 text-center text-white " + getBgColor()
        }
      >
        立即换装
      </div>
    </div>
  );
};

export default FooterUploadImage;
