import React, { useEffect, useState } from "react";
import DialogOpenMember from "../components/DialogOpenMember";
import { Upload } from "../service/upload";
import {
  commonInterface,
  getBgColor,
  getFooterTips,
  getTextColor,
  isAndroid,
  isCanCreate,
  query,
  run,
} from "../utils/utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import "../assets/draw.css";
import { creatable, createGraphApi, redrawSamples } from "../service/api";
import {
  isInApp,
  nativeUploadHDImageHandler,
  waitFor,
} from "../utils/drawUtils";
import { Toast } from "antd-mobile";
import CompareImage from "../components/CompareImage";
import Loading from "../components/Loading";

let drawingId = "";
const App: React.FC = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const detailId = params.get("detail_id");

  const [isShowMember, setIsShowMember] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [samples, setSamples] = useState([]);
  const [sampleIndex, setSampleIndex] = useState(0);
  const [showLoading, setShowLoading] = useState(false);
  const [activeImage, setActiveImage] = useState({
    inputImage: "",
    outputImage: "",
  });

  const [log, setLog] = useState("");
  const [tips, setTips] = useState([]);
  const [titles, setTitles] = useState([]);
  const [estimatedSeconds, setEstimatedSeconds] = useState(0);

  useEffect(() => {
    redrawSamples({ type: 3 }).then((res: any) => {
      if (res.code === 0 && res.data.list.length > 0) {
        setSamples(res.data.list);
        setActiveImage(res.data.list[0]);
      }
    });
  }, []);
  const repairHandler = async (
    inputImage: string,
    width: number,
    height: number,
    size: number
  ) => {
    const createResult: any = await createGraphApi({
      inputImage,
      inputImageWidth: width,
      inputImageHeight: height,
      inputImageSize: size,
      type: 3,
    });

    if (createResult.code === 0) {
      drawingId = createResult.data.id;
      commonInterface("setDrawingId", {
        drawingId: createResult.data.id,
      });
      const random = (Math.random() * 8 + 3) >> 0; // 3 ~ 10秒，之后在请求
      console.info(random + "秒后，发起第一次请求");
      await waitFor(random * 1000);

      try {
        const responseFirst: any = await query(createResult.data.id);
        console.info("responseFirst", responseFirst);
        if(responseFirst.data && responseFirst.data.titles){
          setTitles(responseFirst.data.titles);
          setTips(responseFirst.data.tips);
          setEstimatedSeconds(responseFirst.data.estimatedSeconds);
        } else {
          console.info('太快了，没拿到tips')
        }

        console.info("responseFirst.code", responseFirst);
        if(responseFirst.code !== 0) {
          Toast.show(responseFirst["msg"]);
          setShowLoading(false);
          setBtnDisable(false);
          commonInterface("setDrawingId", { drawingId: "" });
          return
        }

        const result = await run(createResult.data.id);
        console.info(result);
        setTips([]);
        setShowLoading(false);
        setBtnDisable(false);
        drawingId = "";
        commonInterface("setDrawingId", { drawingId: "" });
        if (result["code"] === 0) {
          commonInterface("refreshImageHistory");
          const scheme =
            "with://com.with.fm/app/h5?url=" +
            encodeURIComponent(
              window.location.origin +
                "/hd_result?from=draw&isFullScreen=true&detail_id=" +
                createResult.data.id
            );
          commonInterface("openScheme", { scheme });
        } else {
          Toast.show(result["msg"]);
        }
      } catch (e) {
        console.info("catch.code", e);
        setShowLoading(false);
        setBtnDisable(false);
        commonInterface("setDrawingId", { drawingId: "" });
      }
    } else {
      Toast.show(createResult["msg"]);
      setBtnDisable(false);
      setShowLoading(false);
      commonInterface("setDrawingId", { drawingId: "" });
    }
  };
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        // 用户离开了 WebView
        console.log("用户离开了 WebView");
      } else if (document.visibilityState === "visible") {
        // 用户回到了 WebView
        console.log("用户回到了 WebView");
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  const uploadImage = async (event: any) => {
    setBtnDisable(true);

    try {
      const isCan: any = await creatable({ type: 3 });
      if (isCan.code === 0 && isCan.data.creatable) {
        setShowLoading(true);
        const file: any = event.target.files[0];
        const res: any = await Upload(file);
        await repairHandler(res.url, 1389, 1852, 0);
      } else {
        setIsShowMember(true);
        setBtnDisable(false);
        setShowLoading(false);
      }
    } catch (e: any) {
      setBtnDisable(false);
    }
  };

  window.uploadImageResult = async (
    url: string,
    width: any,
    height: any,
    size: any
  ) => {
    console.info(width, height);
    setLog(`width:${width},height:${height},size:${size}`);
    setBtnDisable(true);
    const isCan: any = await creatable({ type: 3 });
    try {
      if (isCan.code === 0 && isCan.data.creatable) {
        setShowLoading(true);
        await repairHandler(url, width, height, size);
      } else {
        setIsShowMember(true);
        setBtnDisable(false);
        setShowLoading(false);
      }
    } catch (e: any) {
      Toast.show(e);
      setBtnDisable(false);
    }
  };

  return (
    <div className="bg-bg flex flex-col justify-between h-full pt-20">
      <div className="overflow-auto pb-2.5 flex flex-col justify-between flex-1">
        <div className="repair mb-1.67">
          <div className="h-21 px-1.67">
            <CompareImage activeImage={activeImage} />
          </div>
          <div className="flex items-center justify-between w-27.34 mt-1.67 mx-auto">
            {samples.map((item: any, index) => {
              return (
                <div key={index}>
                  <div
                    className="w-12.92 h-9.67 relative"
                    onClick={() => {
                      setSampleIndex(index);
                      setActiveImage(samples[index]);
                    }}
                  >
                    {sampleIndex === index && (
                      <>
                        <div className="rounded-1 z-50 absolute w-full h-full border-zi border-0.25"></div>
                        <div className="w-1.67 h-1.67 bg-zi absolute left-50 top-f0.3 rotate-45 origin-center ml-f0.835"></div>
                      </>
                    )}
                    <img
                      className="w-full h-full rounded-1 relative"
                      src={item.inputImage}
                    />
                  </div>
                  <div className="text-center mt-1">示例{index + 1}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="text-201 text-1 px-1.33">
          {getFooterTips()}
          <div
            className={"text-center text-16 " + getTextColor()}
            onClick={() => {
              const scheme = `with://com.with.fm/app/ai/draw/history?title=${encodeURIComponent(
                "历史高清"
              )}&name=hd&group=0&type=3`;
              commonInterface("openScheme", { scheme });
            }}
          >
            历史记录
          </div>
        </div>
      </div>
      <div
        className={
          "pt-1 px-1.33 " +
          (isAndroid ? "pb-12" : "pb-3.83") +
          " bg-white rounded-t-1.67 shadow-up " +
          (btnDisable ? "opacity-50" : "")
        }
        onClick={nativeUploadHDImageHandler}
      >
        <div
          className={
            "rounded-2.33 p-1.17 text-1.67 text-center text-white relative " +
            getBgColor()
          }
        >
          {isInApp() ? null : (
            <input
              type="file"
              className="left-0 top-0 opacity-0 w-full h-full absolute"
              id="input"
              onChange={uploadImage}
            ></input>
          )}
          选择图片
        </div>
      </div>
      <DialogOpenMember
        isShow={isShowMember}
        hideDialog={() => setIsShowMember(false)}
      ></DialogOpenMember>
      {showLoading && (
        <Loading
          log={log}
          tips={tips}
          titles={titles}
          isShow={showLoading}
          estimatedSeconds={estimatedSeconds}
        />
      )}
    </div>
  );
};

export default App;
