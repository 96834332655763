export const baseUrl = "https://dev-api.hwith.top/";
export const OSSURL =
  window.location.host === "ai.hwith.cn"
    ? "https://with-oss.oss-cn-beijing.aliyuncs.com"
    : window.location.host === "ai.us.hwith.top"
    ? "https://with-us.oss-us-west-1.aliyuncs.com"
    : "https://with-live.oss-cn-beijing.aliyuncs.com";

export const like = require("../assets/images/like.png");
export const like1 = require("../assets/images/like1.png");
export const like2 = require("../assets/images/like2.png");
export const like11 = "bg-[url('../assets/images/like11.png')]";
export const like22 = "bg-[url('../assets/images/like22.png')]";
export const collect = require("../assets/images/collect.png");
export const collect1 = require("../assets/images/collect1.png");
export const collect2 = require("../assets/images/collect2.png");
export const collect11 = "bg-[url('../assets/images/collect11.png')]";
export const collect22 = "bg-[url('../assets/images/collect22.png')]";

export const showConfig: any = {
  history: {
    android: {
      with: 219,
      aibeauty: 9,
    },
    ios: {
      with: 99,
    },
  },
  uploadAvatar: {
    android: {
      with: 219,
      aibeauty: 9,
    },
    ios: {
      with: 99,
    },
  },
  collect: {
    android: {
      with: 218,
      aibeauty: 8,
    },
    ios: {
      with: 98,
    },
  },
};
