import axios, {AxiosError,} from "axios";
import {Toast} from "antd-mobile";
import JSON_BIG from "json-bigint"; //基础URL，axios将会自动拼接在url前

//基础URL，axios将会自动拼接在url前
//process.env.NODE_ENV 判断是否为开发环境 根据不同环境使用不同的baseURL 方便调试
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
//默认请求超时时间
axios.defaults.timeout = 60000;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["platform"] = "android";
axios.defaults.withCredentials = true;
//创建axios实例
axios.defaults.headers["client-type"] = 2;
const match = window.navigator.userAgent.match(/channel\/(\w+)/);
axios.defaults.headers["channel"] = match ? match[1] : "default_channel";
axios.defaults.transformResponse = [
  function (data) {
    if (!data) return {}; //上传图片是空
    const json = JSON_BIG({
      storeAsString: true,
    });

    return json.parse(data);
  },
];
axios.interceptors.request.use(
  (config) => {
    const cookieObj: any = {};
    document.cookie.split(";").forEach((item) => {
      const arr = item.split("=");
      if (arr.length > 1) cookieObj[arr[0].trim()] = arr[1].trim();
    });
    const token = cookieObj.token;

    config.headers.Authorization = process.env.REACT_APP_AUTHORIZATION
      ? process.env.REACT_APP_AUTHORIZATION
      : token;
    //document.getElementById('token')!.innerHTML = token;
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  (config: any) => {
    if (config.data.code === 500000) {
      Toast.show({
        content: config.data.msg,
      });
      return config;
    } else {
      return config;
    }
  },
  (error: AxiosError) => {
    console.info(error);
    Toast.show({
      content:
        error.message === "Network Error"
          ? "请检查网络"
          : "正在重启服务，请稍后...",
    });
    return Promise.reject(error);
  }
);

//axios返回格式
interface axiosTypes<T> {
  data: T;
  status: number;
  statusText: string;
}

//后台响应数据格式
//###该接口用于规定后台返回的数据格式，意为必须携带code、msg以及result
//###而result的数据格式 由外部提供。如此即可根据不同需求，定制不同的数据格式
interface responseTypes<T> {
  code: number;
  msg: string;
  data: T;
}

const post = <T>(url: string, data: object): Promise<T> => {
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url,
      data,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const postToken = <T>(url: string, data: object, token: string): Promise<T> => {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.Authorization = token;
    axios({
      method: "post",
      url,
      data,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const get = <T>(url: string, data: object): Promise<T> => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: data,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// 导出至外层，方便统一使用
export { post, get, postToken };
