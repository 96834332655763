import React, { useEffect, useRef, useState } from "react";
import {
  blueToHalfAlpha,
  clearCanvas,
  getColorMap,
  getOffsetXY,
  MASK_COLOR,
  paintClick,
  redoHandler,
  saveUndoStack,
  undoHandler,
} from "../../utils/inPaintUtils";
import Hammer from "hammerjs";
import Help from "./Help";

interface IProps {
  inputImage: string;
  colorImage: string;
  paintType: string;
  paintNum: number;
  paintCanvas: any;
  undoStack: any;
  redoStack: any;
  setUndoStack: any;
  setRedoStack: any;
}

let colorMap: any;
let isMousedown = false;
let initScale: any;
let startScale: any;
let endScale: any;
let startOffsetX: any;
let startOffsetY: any;
let endOffsetX: any = 0;
let endOffsetY: any = 0;
let hammer: any;
let hammerStatus: string = "none";
let isWidthImage: any = true;
let lastPosition: any;
const Preview: React.FC<IProps> = (props) => {
  const {
    inputImage,
    paintType,
    colorImage,
    paintCanvas,
    paintNum,
    undoStack,
    redoStack,
    setUndoStack,
    setRedoStack,
  } = props;
  const [scale, setScale] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const [widthFull, setWidthFull] = useState(false);


  const initCanvas = useRef<HTMLCanvasElement>(null);
  const blueCanvas = useRef<HTMLCanvasElement>(null);

  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);

  const [log, setLog] = useState("");

  const [guided, setGuided] = useState(false);

  useEffect(() => {
    if (!initCanvas) return;
    hammer = new Hammer.Manager(initCanvas.current as any, {
      recognizers: [
        [Hammer.Pinch, { enable: true }],
        [Hammer.Pan, { direction: Hammer.DIRECTION_ALL }],
      ],
    });

    if (paintType !== "select") {
      initCanvas.current?.addEventListener("touchstart", mousedown);
      initCanvas.current?.addEventListener("touchend", mouseup);
      initCanvas.current?.addEventListener("touchmove", mousemove);
      hammer.on("pinchmove", handlePinchMove);
      hammer.on("pinchstart", handlePinchStart);
      hammer.on("pinchend", () => {
        setTimeout(() => {
          hammerStatus = "none";
        }, 500);
      });
    } else {
      initCanvas.current?.addEventListener("click", selectEventListener);
      hammer.on("pinchstart", handlePinchStart);
      hammer.on("pinchmove", handlePinchMove);
      hammer.on("panstart", handlePanStart);
      hammer.on("panmove", handlePanMove);
      hammer.on("panend", () => {
        setTimeout(() => {
          hammerStatus = "none";
        }, 500);
      });
    }

    return () => {
      hammer.destroy();
      initCanvas.current?.removeEventListener("click", selectEventListener);
      initCanvas.current?.removeEventListener("touchstart", mousedown);
      initCanvas.current?.removeEventListener("touchmove", mousemove);
      initCanvas.current?.removeEventListener("touchend", mouseup);
    };
  }, [paintType, paintNum, undoStack, redoStack]);

  const selectEventListener = (event: any) => {
    const ctx = initCanvas.current?.getContext("2d", {
      willReadFrequently: true,
    });
    setLog(hammerStatus);
    if (hammerStatus !== "none") return;
    paintClick(event, paintCanvas, ctx, paintType, colorMap);
    blueToHalfAlpha(paintCanvas);
    saveUndoStack(paintCanvas, undoStack, setUndoStack, setRedoStack);
  };
  const mousedown = (event: any) => {
    setLog(hammerStatus);
    const position = getOffsetXY(event, initScale, isWidthImage);
    lastPosition = { offsetX: position.offsetX, offsetY: position.offsetY };
    isMousedown = true;
  };
  const mouseup = (event: any) => {
    setLog(hammerStatus);
    isMousedown = false;
    blueToHalfAlpha(paintCanvas);
    saveUndoStack(paintCanvas, undoStack, setUndoStack, setRedoStack);
  };

  const mousemove = (event: any) => {
    setLog(hammerStatus);
    if (!isMousedown || hammerStatus !== "none") return;
    const position = getOffsetXY(event, initScale, isWidthImage);
    const paintCtx = paintCanvas.current?.getContext("2d", {
      willReadFrequently: true,
    });
    if (!paintCtx) return;
    drawOrClearForLine(paintNum, paintType, paintCtx, position);
  };
  const handlePanMove = (event: any) => {
    hammerStatus = "handlePanMove";
    const newOffsetX = startOffsetX + event.deltaX;
    const newOffsetY = startOffsetY + event.deltaY;
    setOffsetX(newOffsetX);
    setOffsetY(newOffsetY);
    endOffsetX = newOffsetX;
    endOffsetY = newOffsetY;
    setLog("handlePanMove");
  };
  const handlePanStart = () => {
    hammerStatus = "handlePanStart";
    startOffsetX = endOffsetX;
    startOffsetY = endOffsetY;
  };
  const handlePinchStart = () => {
    hammerStatus = "handlePinchStart";
    startOffsetX = endOffsetX;
    startOffsetY = endOffsetY;
    startScale = endScale ? endScale : startScale;
    setLog("handlePinchStart");
  };
  const handlePinchMove = (event: any) => {
    hammerStatus = "handlePinchMove";
    const newScale = startScale * event.scale;
    endScale = newScale;
    setScale(newScale);
    setOffsetX(event.deltaX);
    setOffsetY(event.deltaY);
    endOffsetX = event.deltaX;
    endOffsetY = event.deltaY;
    setLog("handlePinchMove");
  };

  const onloadHandler = (event: any) => {
    const isWidthLong = event.target.naturalWidth / event.target.naturalHeight;
    const isConWidthLong = window.innerWidth / 395;

    if (isWidthLong > isConWidthLong) {
      setScale(window.innerWidth / event.target.naturalWidth);
      startScale = window.innerWidth / event.target.naturalWidth;
      initScale = window.innerWidth / event.target.naturalWidth;
      isWidthImage = true;
    } else {
      setScale(395 / event.target.naturalHeight);
      startScale = 395 / event.target.naturalHeight;
      initScale = 395 / event.target.naturalHeight;
      isWidthImage = false;
    }
    setTimeout(() => {
      createInitCanvas(
        event.target.naturalWidth,
        event.target.naturalHeight,
        event.target
      );
      setLoaded(true);
    }, 500);
  };

  const createInitCanvas = (
    width: number,
    height: number,
    image: HTMLImageElement
  ) => {
    if (initCanvas.current && paintCanvas.current && blueCanvas.current) {
      initCanvas.current.width = width;
      initCanvas.current.height = height;

      const ctx = initCanvas.current.getContext("2d", {
        willReadFrequently: true,
      });
      const blueCtx = blueCanvas.current.getContext("2d", {
        willReadFrequently: true,
      });
      paintCanvas.current.width = width;
      paintCanvas.current.height = height;

      blueCanvas.current.width = width;
      blueCanvas.current.height = height;

      if (ctx && blueCtx) {
        ctx.drawImage(image, 0, 0);
        const imageData = ctx.getImageData(
          0,
          0,
          initCanvas.current.width,
          initCanvas.current.height
        );
        colorMap = getColorMap(imageData);
        blueCtx.fillStyle = `rgba(${MASK_COLOR.r}, ${MASK_COLOR.g}, ${MASK_COLOR.b}, 0.5)`;
        blueCtx.fillRect(0, 0, width, height);
      }
    }
  };

  useEffect(() => {
    if (undoStack.length && !guided) {
      setGuided(true);
    }
  }, [undoStack]);
  const clearHandler = () => {
    clearCanvas(paintCanvas);
    setUndoStack([]);
    setRedoStack([]);
  };
  const unDoHandler = () => {
    undoHandler(paintCanvas, undoStack, redoStack, setUndoStack, setRedoStack);
  };
  const doHandler = () => {
    redoHandler(paintCanvas, undoStack, redoStack, setUndoStack);
  };

  const drawSmoothLine = (
    paintCtx: any,
    x0: any,
    y0: any,
    x1: any,
    y1: any,
    radius: any
  ) => {
    const distance = Math.sqrt((x1 - x0) ** 2 + (y1 - y0) ** 2);
    const angle = Math.atan2(y1 - y0, x1 - x0);
    for (let i = 0; i < distance; i += radius * 0.5) {
      const x = x0 + Math.cos(angle) * i;
      const y = y0 + Math.sin(angle) * i;
      drawCircle(paintCtx, x, y, radius);
    }
  };

  const drawOrClearForLine = (
    paintNum: any,
    paintType: any,
    paintCtx: any,
    position: any
  ) => {
    if (paintType === "draw") {
      drawSmoothLine(
        paintCtx,
        lastPosition.offsetX,
        lastPosition.offsetY,
        position.offsetX,
        position.offsetY,
        paintNum
      );
      lastPosition = position;
    } else if (paintType === "clear") {
      paintCtx.save(); // 保存当前画布状态
      paintCtx.globalCompositeOperation = "destination-out"; // 设置混合模式为目标图像减去源图像
      drawSmoothLine(
        paintCtx,
        lastPosition.offsetX,
        lastPosition.offsetY,
        position.offsetX,
        position.offsetY,
        paintNum
      );
      lastPosition = position;
      paintCtx.restore(); // 恢复之前保存的画布状态
    }
  };

  const drawCircle = (paintCtx: any, x: any, y: any, radius: any) => {
    paintCtx.fillStyle = `rgba(${MASK_COLOR.r},${MASK_COLOR.g},${MASK_COLOR.b},1)`;
    paintCtx.beginPath();
    paintCtx.arc(x, y, radius / 2, 0, Math.PI * 2);
    paintCtx.closePath();
    paintCtx.fill();
  };

  return (
    <div className="h-395 bg-bg flex justify-center items-center relative">
      <div
        className={`relative flex justify-center items-center overflow-hidden h-395`}
      >
        {loaded && (
          <>
            <img
              src={inputImage}
              onContextMenu={(e: any) => {
                e.preventDefault();
              }}
              style={{
                maxWidth: "none",
                transform: `translate(${offsetX}px, ${offsetY}px) scale(${scale})`,
              }}
            />
          </>
        )}
        {!guided && (
          <img
            src={colorImage}
            className="absolute opacity-0"
            crossOrigin="anonymous"
            onLoad={onloadHandler}
            onContextMenu={(e: any) => {
              e.preventDefault();
            }}
            style={{
              maxWidth: "none",
              transform: `translate(${offsetX}px, ${offsetY}px) scale(${scale})`,
            }}
          />
        )}
        <canvas
          className="absolute"
          style={{
            transform: `translate(${offsetX}px, ${offsetY}px) scale(${scale})`,
            opacity: 1,
          }}
          ref={paintCanvas}
        ></canvas>

        {!guided && (
          <>
            <canvas
              className="absolute"
              style={{
                transform: `translate(${offsetX}px, ${offsetY}px) scale(${scale})`,
                opacity: 1,
              }}
              ref={blueCanvas}
            ></canvas>
            <div className="point out"></div>
            <div className="point in"></div>
          </>
        )}
        <canvas
          className="absolute"
          style={{
            transform: `translate(${offsetX}px, ${offsetY}px) scale(${scale})`,
            opacity: 0,
          }}
          ref={initCanvas}
        ></canvas>
      </div>
      {(undoStack.length > 0 || redoStack.length > 0) && (
        <>
          {/*<div*/}
          {/*  onClick={() => clearHandler()}*/}
          {/*  className="bg-[url('../assets/images/changeV2/remask.png')] w-3 h-3 bg-2 bg-center bg-no-repeat bg-black bg-opacity-20 absolute bottom-1.33 left-1.33 rounded-0.42"*/}
          {/*></div>*/}

          <div
            onClick={doHandler}
            className={`${
              redoStack.length === 0
                ? "bg-[url('../assets/images/changeV2/redo2.png')]"
                : "bg-[url('../assets/images/changeV2/redo.png')]"
            } w-3 h-3 bg-2 bg-center bg-no-repeat bg-black bg-opacity-20 absolute bottom-1.33 right-1.33 rounded-0.42`}
          ></div>
          <div
            onClick={unDoHandler}
            className={`${
              undoStack.length === 0
                ? "bg-[url('../assets/images/changeV2/undo2.png')]"
                : "bg-[url('../assets/images/changeV2/undo.png')]"
            } w-3 h-3 bg-2 bg-center bg-no-repeat bg-black bg-opacity-20 absolute bottom-1.33 right-5.67 rounded-0.42`}
          ></div>
        </>
      )}
    </div>
  );
};
export default Preview;
