import React, { useEffect, useState } from "react";
import DialogOpenMember from "../components/DialogOpenMember";
import { Toast } from "antd-mobile";
import { action, detail } from "../service/api";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  commonInterface,
  getDownloadImage,
  getReDrawImage,
  isAndroid,
  isVip,
} from "../utils/utils";
import CompareImage from "../components/CompareImage";

const App: React.FC = () => {
  const navigate = useNavigate();
  const [isShowMember, setIsShowMember] = useState(false);
  const [outputWidth, setOutputWidth] = useState(0);
  const [outputHeight, setOutputHeight] = useState(0);
  const [inputWidth, setInputWidth] = useState(0);
  const [inputHeight, setInputHeight] = useState(0);
  const [reportBanned, setReportBanned] = useState(false);
  const [downloadBannedText, setDownloadBannedText] = useState("");

  const [activeImage, setActiveImage] = useState({
    inputImage: "",
    outputImage: "",
  });

  const [params] = useSearchParams();
  const detailId = params.get("detail_id");
  const from: any = params.get("from");

  useEffect(() => {
    detail({ id: detailId, from: from ? 1 : 2 }).then((res: any) => {
      if (res.code === 0) {
        const image = JSON.parse(res.data.content).images[0];
        setActiveImage({
          inputImage: res.data.inputImage,
          outputImage: image.url,
        });
        setOutputWidth(image.width);
        setOutputHeight(image.height);
        setInputWidth(res.data.inputImageWidth);
        setInputHeight(res.data.inputImageHeight);
        setReportBanned(res.data.reportBanned);
        setDownloadBannedText(res.data.downloadBannedText);
      } else {
        Toast.show(res.msg);
      }
    });
  }, []);

  const downloadHandler = async () => {
    if(reportBanned){
      Toast.show(downloadBannedText)
      return;
    }
    const vip = await isVip();
    action({
      actionName: "download",
      graphId: detailId,
    }).then(() => {});
    if (vip) {
      commonInterface("downloadImage", {
        url: activeImage.outputImage,
      });
    } else {
      setIsShowMember(true);
    }
  };
  return (
    <div className="bg-bg flex flex-col justify-between h-full">
      <div className="overflow-auto flex flex-col justify-center  flex-1 px-1.33 pt-9 ">
        {reportBanned ? (
          <img src={activeImage.outputImage} />
        ) : (
          <CompareImage
            activeImage={activeImage}
            outputWidth={outputWidth}
            outputHeight={outputHeight}
            inputWidth={inputWidth}
            inputHeight={inputHeight}
          />
        )}
      </div>
      <div
        className={
          "pt-1 " +
          (isAndroid ? "pb-12" : "pb-3.83") +
          " bg-white rounded-t-1.67 shadow-up z-40"
        }
      >
        <div className="flex justify-around p-0.42 rounded-0.67">
          {from ? (
            <>
              <div
                onClick={() => {
                  if (from) {
                    commonInterface("closePage");
                  } else {
                    navigate(`/hd?detail_id=${detailId}`, {
                      replace: true,
                    });
                  }
                }}
                className={"btn-item"}
              >
                <img
                  alt=""
                  className="w-1.67 h-1.67 mr-0.33"
                  src={require("../assets/images/" + getReDrawImage())}
                />
                再次修复
              </div>
              <div className="w-0.08 text-266">|</div>
            </>
          ) : null}
          <div className="w-0.08 text-266">|</div>
          <div onClick={downloadHandler} className={"btn-item"}>
            <img
              alt=""
              className="w-1.67 h-1.67 mr-0.33"
              src={require("../assets/images/" + getDownloadImage())}
            />
            下载
          </div>
        </div>
      </div>

      <div
        className="absolute top-4.5 left-1.33 w-3 h-3 bg-[url('../assets/images/back_3.png')] bg-2 bg-center bg-no-repeat"
        onClick={() => {
          commonInterface("closePage");
        }}
      ></div>
      <DialogOpenMember
        isShow={isShowMember}
        hideDialog={() => setIsShowMember(false)}
      ></DialogOpenMember>
    </div>
  );
};

export default App;
