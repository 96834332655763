import React from "react";
import { Dialog } from "antd-mobile";
import {
  commonInterface,
  getMemberBtnImage,
  getMemberImage,
} from "../utils/utils";

interface IProps {
  isShow: boolean;
  hideDialog: Function;
}

const DialogOpenMember: React.FC<IProps> = (props) => {
  const { isShow, hideDialog } = props;
  const jumpToRecharge = () => {
    commonInterface("jumpToRecharge");
    hideDialog();
    //跳转到充值页
  };
  return (
    <Dialog
      visible={isShow}
      closeOnMaskClick={true}
      bodyClassName="bg-transparent"
      content={
        <div
          className={
            getMemberImage() + " w-22.58 h-26.67 bg-contain bg-no-repeat flex items-end"
          }
        >
          <div
            onClick={jumpToRecharge}
            className={
              getMemberBtnImage() +
              " mx-auto mb-2.5 leading-4 bg-contain bg-no-repeat w-17.58 h-4 text-white text-center"
            }
          >
            立即开通
          </div>
        </div>
      }
      closeOnAction
      onClose={() => hideDialog()}
    ></Dialog>
  );
};

export default DialogOpenMember;
