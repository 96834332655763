import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import DialogOpenMember from "../components/DialogOpenMember";
import { Tabs, Toast } from "antd-mobile";
import {
  creatable,
  createGraphApi,
  detail,
  galleryDetail,
  getStyleListApi,
  getSuggestedPrompts,
  styleTabs,
} from "../service/api";
import { Upload } from "../service/upload";
import {
  commonInterface,
  getBgColor,
  getBorderColor,
  getFooterTips,
  getPlaceHolder,
  getRefImage,
  getTextColor,
  isAiBeauty,
  isAndroid,
  isShowInput,
  run,
} from "../utils/utils";
import {
  getStyleBoxWidth,
  isInApp,
  nativeUploadHandler,
  waitFor,
} from "../utils/drawUtils";
import { useNavigate, useSearchParams } from "react-router-dom";
import "../assets/draw.css";

const prompts: Array<string> = [];
const loadingText: Array<string> = [
  "AI绘画发起中...",
  "AI绘画解析中...",
  "AI绘画生成中...",
];
let timer: any;

const styleCacheData: any = [];
const App: React.FC = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const detailId = params.get("detail_id");
  const galleryDetailId = params.get("gallery_detail_id");
  const share: any = params.get("share");
  const [styleList, setStyleList] = useState([]);

  const [tabList, setTabList] = useState([]);
  const [isShowMember, setIsShowMember] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);

  const [data, setData] = useState({
    prompt: "",
    style: { id: 0, name: "", icon: "" },
    user: { nickname: "" },
    aspectRatio: 1,
    inputImage: "",
    quality: 1,
    relevancy: 2,
    content:
      '{"type":2,"images":[{"url":"","originSize":0,"width":0,"height":0,"duration":0}]}',
  });

  const getDetailInfo = () => {
    if (galleryDetailId) {
      return galleryDetail({ id: galleryDetailId }).then((res: any) => {
        return res.data;
      });
    } else if (detailId) {
      return detail({ id: detailId }).then((res: any) => {
        return res.data;
      });
    } else {
      return null;
    }
  };

  const getStyleData = (tab: number, pageInfo: string): any => {
    return getStyleListApi({ tab, pageInfo }).then((res: any) => {
      if (res.data.pageInfo) {
        return getStyleData(tab, res.data.pageInfo).then((resNextData: any) => {
          return [...res.data.list, ...resNextData];
        });
      } else {
        return res.data.list;
      }
    });
  };

  const getTabListData = () => {
    return styleTabs({}).then((res: any) => {
      if (res.code === 0) {
        setTabList(res.data.list);
        return getStyleData(0, "");
      }
    });
  };
  const changeStyleTabHandler = (key: any) => {
    const styleData = styleCacheData[key];
    if (styleData) {
      setStyleList(styleData);
      setData({ ...data, style: styleData[0] });
    } else {
      getStyleData(key, "").then((res: any) => {
        setStyleList(res);
        setData({ ...data, style: res[0] });
        styleCacheData[key] = res;
      });
    }
  };
  useEffect(() => {
    Promise.all([
      getTabListData(),
      getDetailInfo() as any,
      getSuggestedPrompts({ type: 1 }).then((res: any) => {
        if (res.code === 0) {
          prompts.push(...res.data.list);
          return res.data.list;
        }
      }),
    ]).then((res) => {
      setStyleList(res[0]);
      styleCacheData[0] = res[0];

      if (res[1]) {
        res[1].aspectRatio = res[1].aspectRatio !== 0 ? res[1].aspectRatio : 1;
        res[1].quality = res[1].quality !== 0 ? res[1].quality : 1;
        res[1].relevancy = res[1].relevancy !== 0 ? res[1].relevancy : 2;
        if (share) {
          res[1].inputImage = "";
        }
        //如果风格里面曾经的删除了。就默认选第一个
        if (!res[1].style) {
          res[1].style = res[0][0];
        } else {
          const item = res[0].find((item: any) => item.id === res[1].style.id);
          if (!item) {
            res[1].style = res[0][0];
          }
        }
        setData({ ...data, ...res[1] }); //如有detailID,就用如有detailID的结果
      } else {
        setData({ ...data, style: res[0][0] }); //默认选第一个
      }
    });
  }, []);
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setData({ ...data, prompt: event.currentTarget.value });
  };

  const randomPrompts = () => {
    const n = (Math.random() * prompts.length) >> 0;
    setData({ ...data, prompt: prompts[n] ? prompts[n] : "" });
  };
  const clearPrompts = () => {
    if (data.prompt === "") {
      Toast.show("无可清空内容～");
    } else {
      setData({ ...data, prompt: "" });
    }
  };
  const checkForm = () => {
    let result = true;
    const errMsgArr = [];
    if (data.prompt.trim() === "") {
      errMsgArr.push("请输入描述语");
      result = false;
    }
    if (errMsgArr.length > 0) {
      Toast.show(errMsgArr.join("，"));
    }
    return result;
  };

  const showLoadingText = (index: number) => {
    Toast.show({
      icon: "loading",
      duration: 0,
      maskClickable: false,
      content: loadingText[index],
    });

    timer = setTimeout(() => {
      index = index < loadingText.length - 1 ? index + 1 : index;
      showLoadingText(index);
      if (index === loadingText.length - 1) {
        clearTimeout(timer);
      }
    }, 5000);
  };

  const submitHandler = async () => {
    if (checkForm()) {
      if (btnDisable) return;
      setBtnDisable(true);
      try {
        const isCan: any = await creatable({ type: 1 });
        if (isCan.code === 0 && isCan.data.creatable) {
          const aspectRatio = data.aspectRatio;
          const inputImage = data.inputImage;

          const prompt = data.prompt;
          const relevancy = data.relevancy;
          const styleId = data.style.id;
          const quality = data.quality;

          showLoadingText(0);

          const createResult: any = await createGraphApi({
            aspectRatio,
            inputImage,
            quality,
            prompt,
            relevancy,
            styleId,
          });

          if (createResult.code === 0) {
            commonInterface("setDrawingId", {
              drawingId: createResult.data.id,
            });
            const random = (Math.random() * 8 + 3) >> 0; // 3 ~ 10秒，之后在请求
            console.info(random + "秒后，发起第一次请求");
            await waitFor(random * 1000);
            const result = await run(createResult.data.id);
            console.info(result);
            Toast.clear();
            timer && clearTimeout(timer);
            setBtnDisable(false);
            commonInterface("setDrawingId", { drawingId: "" });
            if (result["code"] === 0) {
              commonInterface("refreshImageHistory");
              const scheme =
                "with://com.with.fm/app/h5?url=" +
                encodeURIComponent(
                  window.location.origin +
                    "/draw_result?from=draw&isFullScreen=true&detail_id=" +
                    result["data"]["id"]
                );
              commonInterface("openScheme", { scheme });
            } else {
              Toast.show(result["msg"]);
            }
          } else {
            Toast.clear();
            timer && clearTimeout(timer);
            setBtnDisable(false);
            Toast.show(createResult["msg"]);
          }
        } else {
          setIsShowMember(true);
          setBtnDisable(false);
        }
      } catch (e: any) {
        setBtnDisable(false);
      }
    }
  };

  const uploadImage = async (event: any) => {
    const file: any = event.target.files[0];
    const res: any = await Upload(file);
    setData({ ...data, inputImage: res.url });
  };

  window.uploadImageResult = (url: string) => {
    setData({ ...data, inputImage: url });
  };

  return (
    <div className="bg-white flex flex-col justify-between h-full pt-55">
      <Header
        title="AI绘画"
        backFunc={() => commonInterface("closePage")}
        rightText={isShowInput("history") ? "历史记录" : ""}
        rightFunc={() => {
          commonInterface("openScheme", {
            scheme: `with://com.with.fm/app/ai/draw/history?title=${encodeURIComponent(
              "历史记录"
            )}&name=draw&group=0&type=1`,
          });
        }}
      ></Header>
      <div className="p-1.33 overflow-auto pb-2.5">
        <div className="item-title">
          <div className="icon bg-[url('../assets/images/icon/0.png')]"></div>
          输入描述语<span className="text-red text-251">*</span>
        </div>
        <div className="item-body-rounded">
          <textarea
            className="w-full resize-none h-10 outline-none placeholder-201"
            placeholder={getPlaceHolder()}
            onInput={handleChange}
            value={data.prompt}
            maxLength={500}
          ></textarea>
          <div className="leading-none text-201 flex justify-between items-center">
            <div className="flex ">
              <div
                onClick={() => randomPrompts()}
                className={
                  "text-1 flex items-center border-gray border-0.25 rounded-1.67 px-0.42 py-0.25 font-bold mr-1 " +
                  getTextColor()
                }
              >
                <div
                  className={
                    getRefImage() + " bg-contain h-1.33 w-1.33 mr-0.33"
                  }
                ></div>
                随机一个
              </div>
              <div
                onClick={() => clearPrompts()}
                className="text-1 flex items-center border-gray border-0.25 rounded-1.67 bg-bg px-0.42 py-0.25 text-201 font-bold"
              >
                <div className="bg-[url('../assets/images/del2.png')] bg-contain h-1.33 w-1.33 mr-0.33"></div>
                清空文本
              </div>
            </div>
            {data.prompt.length}/500
          </div>
        </div>
        <div className="item-title">
          <div className="icon bg-[url('../assets/images/icon/1.png')]"></div>
          选择风格<span className="text-red text-251">*</span>
        </div>
        <div
          className={
            "rounded-1.67 pl-1.33 pr-1.33 mb-2.5 border-0.08 border-242 " +
            (tabList.length > 0 ? "pt-1.33" : "")
          }
        >
          <div>
            <Tabs
              className={"my-tabs " + (isAiBeauty ? "ai_beauty" : "with")}
              activeLineMode="fixed"
              defaultActiveKey="0"
              style={{
                "--fixed-active-line-width": "20px", // 自定义宽度
              }}
              onChange={changeStyleTabHandler}
            >
              {tabList.map((item: any) => {
                return <Tabs.Tab title={item.name} key={item.id} />;
              })}
            </Tabs>
          </div>
          <div
            className="item-body-rounded-style"
            style={{ width: getStyleBoxWidth(styleList.length) }}
          >
            {styleList.map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  className={
                    "w-6.67" +
                    (index === styleList.length - 1 ||
                    index === styleList.length - 2
                      ? ""
                      : " mr-1")
                  }
                  onClick={() =>
                    setData({ ...data, style: { ...data.style, id: item.id } })
                  }
                >
                  <div className="w-6.67 h-6.67 relative rounded-1.33 overflow-hidden">
                    <img alt="" src={item.icon} />
                    {data.style.id === item.id ? (
                      <div
                        className={
                          "absolute  rounded-1.33 top-0 w-full h-full border-2px " +
                          getBorderColor()
                        }
                      ></div>
                    ) : null}
                  </div>
                  <div
                    className={
                      "text-center pt-0.33 w-6.67 overflow-hidden white-space-nowrap overflow-ellipsis " +
                      (data.style.id === item.id ? getTextColor() : "")
                    }
                  >
                    {item.name}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="item-title">
          <div className="icon bg-[url('../assets/images/icon/2.png')]"></div>
          选择比例<span className="text-red text-251">*</span>
        </div>
        <div className="item-body-rounded">
          <div className="bg-bg flex justify-between p-0.42 rounded-0.67">
            <div
              onClick={() => {
                setData({ ...data, aspectRatio: 1 });
              }}
              className={
                "option " +
                (data.aspectRatio === 1
                  ? "option-active " + getTextColor()
                  : "")
              }
            >
              <div
                className={
                  "ratio-icon w-1.67 h-1.67 " +
                  (data.aspectRatio === 1 ? getBorderColor() : "")
                }
              ></div>
              正方形
            </div>
            <div className="option-space">
              {data.aspectRatio === 2 ? "|" : ""}
            </div>
            <div
              onClick={() => {
                setData({ ...data, aspectRatio: 3 });
              }}
              className={
                "option " +
                (data.aspectRatio === 3
                  ? "option-active " + getTextColor()
                  : "")
              }
            >
              <div
                className={
                  "ratio-icon w-1.5 h-1.83 " +
                  (data.aspectRatio === 3 ? getBorderColor() : "")
                }
              ></div>
              竖图
            </div>
            <div className="option-space">
              {data.aspectRatio === 1 ? "|" : ""}
            </div>
            <div
              onClick={() => {
                setData({ ...data, aspectRatio: 2 });
              }}
              className={
                "option " +
                (data.aspectRatio === 2
                  ? "option-active " + getTextColor()
                  : "")
              }
            >
              <div
                className={
                  "ratio-icon w-1.83 h-1.5 " +
                  (data.aspectRatio === 2 ? getBorderColor() : "")
                }
              ></div>
              横图
            </div>
          </div>
        </div>
        <div className="item-title">
          <div className="icon bg-[url('../assets/images/icon/5.png')]"></div>
          上传参考图
        </div>
        <div className="item-body-rounded flex flex-col justify-center items-center">
          <div className="relative bg-bg h-6.67 w-6.67 rounded-0.83 border-201 border-0.08 border-dashed flex justify-center items-center mb-1.08 text-201 text-3">
            {data.inputImage !== "" ? (
              <>
                <div
                  className="h-6.67 w-6.67 bg-no-repeat bg-center rounded-0.83 bg-contain"
                  style={{ backgroundImage: "url(" + data.inputImage + ")" }}
                ></div>
                <img
                  className="w-1.33 h-1.33 right-f0.665 top-f0.665 absolute"
                  src={require("../assets/images/delete.png")}
                  onClick={() => setData({ ...data, inputImage: "" })}
                />
              </>
            ) : (
              <img
                className="w-2"
                onClick={nativeUploadHandler}
                src={require("../assets/images/add.png")}
              />
            )}
            {isInApp() ? null : (
              <input
                type="file"
                className="opacity-0 h-6.67 w-6.67 absolute"
                id="input"
                onChange={uploadImage}
              ></input>
            )}
          </div>
          <div className="text-center text-card">
            让AI在参考图基础上，按描述进行创作
          </div>
        </div>
        <div className="item-title">
          <div className="icon bg-[url('../assets/images/icon/4.png')]"></div>
          质量
        </div>
        <div className="item-body-rounded">
          <div className="bg-bg flex justify-between p-0.42 rounded-0.67">
            <div
              onClick={() => setData({ ...data, quality: 1 })}
              className={
                "option " +
                (data.quality === 1 ? "option-active " + getTextColor() : "")
              }
            >
              标准
            </div>
            <div
              onClick={() => setData({ ...data, quality: 2 })}
              className={
                "option " +
                (data.quality === 2 ? "option-active " + getTextColor() : "")
              }
            >
              更多细节
            </div>
          </div>
        </div>

        <div className="item-title">
          <div className="icon bg-[url('../assets/images/icon/0.png')]"></div>
          描述词相关度
        </div>
        <div className="item-body-rounded">
          <div className="bg-bg flex justify-between p-0.42 rounded-0.67">
            <div
              onClick={() => setData({ ...data, relevancy: 1 })}
              className={
                "option " +
                (data.relevancy === 1 ? "option-active " + getTextColor() : "")
              }
            >
              低
            </div>
            <div className="option-space">
              {data.relevancy === 3 ? "|" : ""}
            </div>
            <div
              onClick={() => setData({ ...data, relevancy: 2 })}
              className={
                "option " +
                (data.relevancy === 2 ? "option-active " + getTextColor() : "")
              }
            >
              中
            </div>
            <div className="option-space">
              {data.relevancy === 1 ? "|" : ""}
            </div>
            <div
              onClick={() => setData({ ...data, relevancy: 3 })}
              className={
                "option " +
                (data.relevancy === 3 ? "option-active " + getTextColor() : "")
              }
            >
              高
            </div>
          </div>
        </div>
        <div className="text-201 text-1">{getFooterTips()}</div>
      </div>
      <div
        className={
          "pt-1 px-1.33 " +
          (isAndroid ? "pb-12" : "pb-3.83") +
          " bg-white rounded-t-1.67 shadow-up " +
          (btnDisable ? "opacity-50" : "")
        }
        onClick={submitHandler}
      >
        <div
          className={
            "rounded-2.33 p-1.17 text-1.67 text-center text-white " +
            getBgColor()
          }
        >
          生成画作
        </div>
      </div>
      <DialogOpenMember
        isShow={isShowMember}
        hideDialog={() => setIsShowMember(false)}
      ></DialogOpenMember>
    </div>
  );
};

export default App;
