import React, { useEffect, useState } from "react";

import { Toast } from "antd-mobile";
import "../assets/pay_with.css";
import {
  getLangOffiaccountMdseList,
  externalLogin,
  orderApplyJsapi,
} from "../service/api";

const authCode = new URLSearchParams(window.location.search).get(
  "code"
) as string;

let token:any = localStorage.getItem("token") ? localStorage.getItem("token") : "";
const App: React.FC = () => {
  const [list, setList] = useState([]);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [pricePerMonth, setPricePerMonth] = useState(0);
  const onBridgeReady = (res: any) => {
    window.WeixinJSBridge.invoke(
      "getBrandWCPayRequest",
      {
        appId: res.data.appid, //公众号ID，由商户传入
        timeStamp: res.data.timestamp, //时间戳，自1970年以来的秒数
        nonceStr: res.data.nonce, //随机串
        package: "prepay_id=" + res.data.prepayId,
        signType: "RSA", //微信签名方式：
        paySign: res.data.sign, //微信签名
      },
      function (res: any) {
        if (res.err_msg === "get_brand_wcpay_request:ok") {
          Toast.show({
            content: "购买成功",
            duration: 5000,
          });
        } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
          Toast.show({
            content: "购买已取消",
            duration: 5000,
          });
        } else {
          Toast.show({
            content: "购买失败",
            duration: 5000,
          });
        }
      }
    );
  };

  useEffect(() => {
    if (!token) {
      externalLogin({ app: 39, authCode }).then((res: any) => {
        const { code, data }: any = res;
        if (code === 0) {
          token = data.token;
          localStorage.setItem("token", data.token);
        } else {
          Toast.show(res.desc);
        }
      });
    }

    getLangOffiaccountMdseList({}).then((res: any) => {
      if (res.code === 0) {
        setList(res.data.mdseList);
        setPricePerMonth(res.data.pricePerMonth);
      } else {
        Toast.show(res.desc);
      }
    });
    return () => {
      console.log(88);
    };
  }, []);
  const payHandler = (index: number) => {
    console.info(index);
    const item: any = list[index];
    setActiveIndex(index);
    orderApplyJsapi(
      {
        amount: item.currentAmount,
        clientIp: "",
        mdseId: item.id,
        mdseNum: 1,
        mdseType: item.type,
      },
      token
    ).then((res: any) => {
      if (res.code === 0) {
        onBridgeReady(res);
      } else {
        Toast.show(res.desc);
      }
    });
  };
  return (
    <div className="px-1.67 pt-3.83 pb-1.67  bg-brown text-brown">
      <div className="mb-2.5 text-center text-3 font-bold">
        {pricePerMonth / 100}元<span className="text-1.67">/月</span>
      </div>
      <div className="flex flex-row justify-between">
        {list.map((item: any, index) => {
          return (
            <div
              key={index}
              className={index === activeIndex ? "item active" : "item"}
              onClick={() => payHandler(index)}
            >
              {item.name}
              {item.badgeText && <div className="top">{item.badgeText}</div>}
            </div>
          );
        })}
      </div>
      <div className="title">权益对比</div>
      <table>
        <tbody>
          <tr>
            <td className="th">权益名称</td>
            <td className="th">免费版</td>
            <td style={{ color: "rgba(255, 195, 0, 1)", fontWeight: "bold" }}>
              付费版
            </td>
          </tr>
          <tr>
            <td>使用次数</td>
            <td>3次</td>
            <td>不限制</td>
          </tr>
          <tr>
            <td>使用间隔</td>
            <td>20s起</td>
            <td>10s</td>
          </tr>
          <tr>
            <td>聊天模式</td>
            <td>联系上文5句</td>
            <td>联系上文20句</td>
          </tr>
          <tr>
            <td>文本模式</td>
            <td>联系上文5句</td>
            <td>联系上文20句</td>
          </tr>
          <tr>
            <td>语法纠正</td>
            <td>联系上文5句</td>
            <td>联系上文20句</td>
          </tr>
          <tr>
            <td>雅思口语</td>
            <td>不支持</td>
            <td>联系全部上文</td>
          </tr>
          <tr>
            <td>模拟面试</td>
            <td>不支持</td>
            <td>联系全部上文</td>
          </tr>
          <tr>
            <td>生词训练</td>
            <td>不支持</td>
            <td>联系全部上文</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default App;
