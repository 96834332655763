import React from "react";

interface IProps {
  title: string;
  rightText: String;
  rightFunc?: any;
  backFunc?: any;
}

const Header: React.FC<IProps> = (props) => {
  const { title, backFunc, rightText, rightFunc } = props;

  return (
    <header
      className={
        "flex justify-between font-bold items-center px-1.33 text-1.5 h-3.67 dark:text-white"
      }
    >
      {backFunc ? (
        <div
          className="bg-[url('../assets/images/back_1.webp')] h-2 w-2 bg-contain"
          onClick={backFunc}
        ></div>
      ) : (
        <div className="w-1.83"></div>
      )}
      {title}
      <div className="text-1" onClick={rightFunc}>
        {rightText}
      </div>
    </header>
  );
};

export default Header;
