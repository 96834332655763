import React, { useEffect, useState } from "react";
import { getBgColor, getBorderColor, getTextColor } from "../utils/utils";

interface IProps {
  log: string;
  tips: string[];
  titles: string[];
  estimatedSeconds: number;
  isShow: boolean;
}

let tipsTimer: any;
let timer: any;
const Loading: React.FC<IProps> = (props) => {
  const { log, tips, titles, estimatedSeconds, isShow } = props;
  const [tip, setTip] = useState(
    "伟大的事情需要时间。 请不要关闭应用程序或锁定您的设备"
  );
  const [text, setText] = useState<string>("正在初始化...");
  const [p, setP] = useState<number>(0);
  const showTitles = (titleArr: [], index: number) => {
    setText(titleArr[index]["text"]);
    const per = (titleArr[index]["duration"] / estimatedSeconds) * 83;
    setP(per);
    timer = setTimeout(() => {
      index = index < titleArr.length - 1 ? index + 1 : index;
      showTitles(titleArr, index);
      if (index === titleArr.length - 1) {
        console.info("end");
        clearTimeout(timer);
      }
    }, titleArr[index]["duration"] * 1000);
  };
  const formatLoadingArr = (arr: string[]) => {
    const result: any = [];
    const one = estimatedSeconds / arr.length;
    for (let i = 0; i < arr.length; i++) {
      let obj: any = { text: arr[i] };
      obj.duration = one * (i + 1);
      result.push(obj);
    }
    console.info(result);
    return result;
  };

  useEffect(() => {
    console.info("init", isShow, tips);
    if (isShow && tips.length > 0) {
      clearInterval(tipsTimer);
      clearTimeout(timer);
      let index = 0;
      setTip(tips[index]);
      showTitles(formatLoadingArr(titles), 0);
      tipsTimer = setInterval(() => {
        index = index === tips.length - 1 ? 0 : ++index;
        setTip(tips[index]);
        console.info(tips[index]);
      }, 5000);
    }
  }, [isShow, tips]);
  useEffect(() => {
    return () => {
      console.info("走了");
      clearInterval(tipsTimer);
      clearTimeout(timer);
    };
  }, []);
  return (
    <div className="fixed left-0 top-0 w-full h-full bg-white z-99 bg-opacity-80 flex flex-col items-center pt-15 backdrop-blur-lg">
      <img
        className="w-16.67 h-16.67"
        src="https://with-oss.oss-cn-beijing.aliyuncs.com/h5/common/loading.webp"
      />
      <div className="text-1.67 mb-1">{text}</div>
      <div className="w-22.58 text-center">
        <div
          className={`border-0.08 ${getBorderColor()} w-250 rounded-1.25 flex items-center mb-1.33`}
        >
          <div
            className={`border-zi ${getBgColor()} h-0.92 m-0.17 transition-all rounded-0.42`}
            style={{ width: p + "%" }}
          ></div>
          <div className={`text-0.83 ${getTextColor()} ml-0.17`}>
            {p.toFixed(0) + "%"}
          </div>
        </div>
      </div>
      <div className="text-card text-center mt-1.33 w-16.67">{tip}</div>
    </div>
  );
};

export default Loading;
