import React, { useState } from "react";

interface IProps {
  activeImage: {
    inputImage: string;
    outputImage: string;
  };
  outputWidth?: number;
  outputHeight?: number;
  inputWidth?: number;
  inputHeight?: number;
}

const CompareImage: React.FC<IProps> = (props) => {
  const { activeImage, outputWidth, outputHeight, inputWidth, inputHeight } =
    props;
  const [percentage, setPercentage] = useState(50);
  const [isDragging, setIsDragging] = useState(false);
  const dragHandler = (event: any) => {
    if (isDragging) {
      const containerWidth = window.innerWidth - 15.96 * 2;
      const left = event.touches[0].clientX - 15.96;
      const newPercentage = (left / containerWidth) * 100;
      setPercentage(
        newPercentage < 10 ? 10 : newPercentage > 90 ? 90 : newPercentage
      );
    }
  };
  const handleTouchStart = () => {
    setIsDragging(true);
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  return (
    <div className="h-full overflow-hidden relative">
      <div
        className="h-full bg-contain bg-no-repeat bg-center relative"
        style={{
          backgroundImage: `url(${activeImage.outputImage})`,
        }}
      >
        <div
          className="absolute top-0 left-0 right-0 bottom-0 overflow-hidden"
          style={{
            width: `${percentage}%`,
          }}
        >
          <div
            className="h-full bg-contain bg-no-repeat bg-center bg-center"
            style={{
              width: `${window.innerWidth - 15.96 * 2}px`,
              backgroundImage: `url(${activeImage.inputImage})`,
            }}
          ></div>
        </div>
      </div>
      <div
        onTouchStart={handleTouchStart}
        onTouchMove={dragHandler}
        onTouchEnd={handleTouchEnd}
        className="absolute bg-[url('../assets/images/lr.png')] w-8 h-8 bg-no-repeat bg-center rounded-1.5 bg-3 bottom-20% ml-f4"
        style={{ left: `${percentage}%` }}
      ></div>
      <div className="compare-line" style={{ left: `${percentage}%` }}></div>
      <div
        className={`absolute left-1.33 top-1.33 ${
          inputWidth ? "bg-bg px-0.42" : "bg-black pr-0.42 pl-0"
        } bg-opacity-40 text-0.83 flex rounded-0.67 py-0.42 text-black text-center flex justify-center items-center`}
      >
        {inputWidth ? (
          <>
            前<br />
            {`${inputWidth}*${inputHeight}`}
          </>
        ) : (
          <>
            <img className="w-1.33" src={require("../assets/images/r.png")} />
            <div className="text-white">修复前</div>
          </>
        )}
      </div>
      <div  className={`absolute right-1.33 top-1.33 ${
          inputWidth ? "bg-bg px-0.42" : "bg-white pr-0.42 pl-0"
      } bg-opacity-40 text-0.83 flex rounded-0.67 py-0.42 text-black text-center flex justify-center items-center`}>
        {outputWidth ? (
          <>
            后<br />
            {`${outputWidth}*${outputHeight}`}
          </>
        ) : (
          <>
            <img className="w-1.33" src={require("../assets/images/r.png")} />
            <div className="text-white">修复后</div>
          </>
        )}
      </div>
    </div>
  );
};

export default CompareImage;
