import React, { MouseEventHandler } from "react";
import { Toast } from "antd-mobile";
import { maskPredict } from "../../service/api";
import { getBgColor, getTextColor } from "../../utils/utils";

const MaskList = [
  {
    title: "标记替换",
    img: "bg-[url('../assets/images/change/0.png')]",
    imgActive: "bg-[url('../assets/images/change/00.png')]",
    type: "positive",
  },
  {
    title: "标记保留",
    img: "bg-[url('../assets/images/change/1.png')]",
    imgActive: "bg-[url('../assets/images/change/11.png')]",
    type: "negative",
  },
];

interface IProps {
  currentPen: string;
  setCurrentPen: Function;
  positivePoints: { x: number; y: number }[];
  negativePoints: { x: number; y: number }[];
  clearPointsHandler: MouseEventHandler<HTMLDivElement>;
  inputImage: any;
  setMask: Function;
  setPreview: Function;
  maskBtnDisable: boolean;
  setMaskBtnDisable: Function;
  setPositivePointsTemp: Function;
  setNegativePointsTemp: Function;
}

const Points: React.FC<IProps> = (props) => {
  const {
    currentPen,
    setCurrentPen,
    inputImage,
    positivePoints,
    negativePoints,
    clearPointsHandler,
    setPreview,
    setMask,
    maskBtnDisable,
    setMaskBtnDisable,
    setPositivePointsTemp,
    setNegativePointsTemp,
  } = props;

  const submitHandler = () => {
    if (!maskBtnDisable) {
      getMaskHandler();
    }
  };
  const getMaskHandler = () => {
    Toast.show({
      icon: "loading",
      duration: 0,
      maskClickable: false,
      content: "智能识别标记区域中...",
    });
    maskPredict({ inputImage, positivePoints, negativePoints }).then(
      (res: any) => {
        Toast.show({
          icon: "success",
          duration: 2000,
          maskClickable: false,
          content: "智能识别成功～",
        });
        if (res.code === 0) {
          setMask(res.data.mask);
          setPreview(res.data.preview);
          setPositivePointsTemp(positivePoints);
          setNegativePointsTemp(negativePoints);
          setMaskBtnDisable(true);
        } else {
          Toast.show(res.desc || res.msg);
        }
      }
    );
  };
  return (
    <div className="rounded-1.67 border-0.08 border-242 py-1.33 mb-1.67 flex flex-col items-center">
      <div className="flex items-center justify-between px-2.92 self-stretch">
        {MaskList.map((item, index) => {
          return (
            <div
              key={index}
              className="flex flex-col items-center"
              onClick={() => setCurrentPen(item.type)}
            >
              <div
                className={`w-4.67 h-4.67 flex justify-center items-center rounded-2.33 mb-0.42 ${
                  currentPen === item.type ? getBgColor() : "bg-bg"
                }`}
              >
                <div
                  className={`${
                    currentPen === item.type ? item.imgActive : item.img
                  } w-2.5 h-2.5 bg-contain`}
                ></div>
              </div>
              <div
                className={`text-1 ${
                  currentPen === item.type ? getTextColor() : "text-201"
                }`}
              >
                {item.title}
              </div>
            </div>
          );
        })}
        <div
          className="flex flex-col items-center"
          onClick={clearPointsHandler}
        >
          <div
            className={`w-4.67 h-4.67 flex justify-center items-center rounded-2.33 mb-0.42 bg-bg`}
          >
            <div
              className={`bg-[url('../assets/images/change/del.png')] w-2.5 h-2.5 bg-contain`}
            ></div>
          </div>
          <div className={`text-1 text-201`}>清空标记</div>
        </div>
      </div>
      <div
        className={`rounded-1.67 py-1 px-2.25 ${getBgColor()} text-white mt-1.33 ${
          maskBtnDisable ||
          (positivePoints.length === 0 && negativePoints.length === 0)
            ? "opacity-50"
            : ""
        }`}
      >
        <div
          className="flex justify-center items-center text-1"
          onClick={submitHandler}
        >
          <div className="bg-[url('../assets/images/change/re_mask.png')] w-1.33 h-1.33 bg-contain mr-0.67"></div>
          <div className="leading-1.17">应用标记</div>
          <div className="text-251">*</div>
        </div>
      </div>
    </div>
  );
};

export default Points;
