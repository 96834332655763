import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import reportWebVitals from "./reportWebVitals";

import PayWith from "./views/PayWith";
import PayWithMj from "./views/PayWithMj";
import PaySloth from "./views/PaySloth";
import Draw from "./views/Draw";
import DrawResult from "./views/DrawResult";
import AiUserList from "./views/AiUserList";
import "./assets/base.css";
import Photo from "./views/Photo";
import PhotoResult from "./views/PhotoResult";
import Hd from "./views/Hd";
import HdResult from "./views/HdResult";
import Change from "./views/Change";
import ChangeForm from "./views/ChangeForm";
import ChangeResult from "./views/ChangeResult";
import ChangeV2 from "./views/ChangeV2";
import ChangeFormV2 from "./views/ChangeFormV2";

if (window.navigator.userAgent.match("isNightMode:1")) {
  document.body.className = "dark";
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: null,
  },
  {
    path: "/pay_with",
    element: <PayWith />,
  },
  {
    path: "/pay_with_mj",
    element: <PayWithMj />,
  },
  {
    path: "/pay_sloth",
    element: <PaySloth />,
  },
  {
    path: "/draw",
    element: <Draw />,
  },
  {
    path: "/draw_result",
    element: <DrawResult />,
  },
  {
    path: "/ai_user_list",
    element: <AiUserList />,
  },
  {
    path: "/photo",
    element: <Photo />,
  },
  {
    path: "/photo_result",
    element: <PhotoResult />,
  },
  {
    path: "/hd",
    element: <Hd />,
  },
  {
    path: "/hd_result",
    element: <HdResult />,
  },
  {
    path: "/change",
    element: <Change />,
  },
  {
    path: "/change_form",
    element: <ChangeForm />,
  },
  {
    path: "/change_result",
    element: <ChangeResult />,
  },
  {
    path: "/change_v2",
    element: <ChangeV2 />,
  },
  {
    path: "/change_form_v2",
    element: <ChangeFormV2 />,
  },
]);
root.render(<RouterProvider router={router} />);
reportWebVitals();
