import React from "react";
import { getBgColor } from "../../utils/utils";

interface IProps {
  isShow: boolean;
  hideDialog: Function;
  paintType: string;
}

const config: {
  [key: string]: {
    title: string;
    subtitle: string;
    body: string;
    video: string;
  };
} = {
  select: {
    title: "如何使用快速选择工具",
    subtitle: "加减面积",
    body: "图片分区成功后，可以单击选择一个区域，再次单击取消选择该区域。",
    video: require("../../assets/video/edit_cut_every_thing.gif"),
  },
  draw: {
    title: "如何使用笔刷工具",
    subtitle: "笔刷区域",
    body: "通过笔刷按钮标记区域，调整笔刷大小以修改选择的区域。",
    video: require("../../assets/video/edit_brush.gif"),
  },
  clear: {
    title: "如何使用橡皮擦工具",
    subtitle: "擦除区域",
    body: "通过橡皮擦按钮删除区域，调整橡皮擦大小以修改选择的区域。",
    video: require("../../assets/video/edit_erase.gif"),
  },
};
const Footer: React.FC<IProps> = (props) => {
  const { isShow, hideDialog, paintType } = props;

  const dontShowHandler = () => {
    hideDialog(false);
  };
  return isShow ? (
    <div className="fixed w-full left-0 top-0 h-full bg-black bg-opacity-50 flex items-center justify-center z-100">
      <div className="bg-white text-31 w-90 rounded-1.67 p-1.67 flex flex-col items-center relative">
        <div className="text-1.67 font-bold">{config[paintType]["title"]}</div>
        <img src={config[paintType]["video"]} className="my-1.67" />
        <div className="text-1.5 mb-0.42 font-bold">
          {config[paintType]["subtitle"]}
        </div>
        <div className="text-34 opacity-80 text-1.25  text-center">
          {config[paintType]["body"]}
        </div>
        <div
          onClick={dontShowHandler}
          className={`rounded-2.33 p-1.17 mt-1.67 self-stretch text-center ${getBgColor()}`}
        >
          <div className="text-white font-bold text-1.67">我知道了</div>
        </div>
      </div>
    </div>
  ) : null;
};

export default Footer;
