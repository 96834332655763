import React, { useEffect, useRef, useState } from "react";

import { Toast } from "antd-mobile";
import "../assets/pay_sloth.css";
import {
  getSlothMdseList,
  slothOrderApplyJsapi,
  getWxOpenId,
} from "../service/api";
import {
  isAndroid,
} from "../utils/utils";

const authCode = new URLSearchParams(window.location.search).get(
  "code"
) as string;

const TABS = [
  { label: "果实", type: 1 },
  { label: "会员", type: 2 },
  { label: "特权", type: 3 },
  { label: "AI点数", type: 4 },
];

const App: React.FC = () => {
  const [list, setList] = useState([]);
  const [activeTab, setActiveTab] = useState(TABS[0].type);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [openId, setOpenId] = useState('');
  const [slothId, setSlothId] = useState('');
  const slothIdRef = useRef<string>('');

  const handleSlothIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSlothId(event.target.value);
  };

  useEffect(() => {
    slothIdRef.current = slothId;
  }, [slothId]);

  const onBridgeReady = (res: any) => {
    window.WeixinJSBridge.invoke(
      "getBrandWCPayRequest",
      {
        appId: res.data.appid, //公众号ID，由商户传入
        timeStamp: res.data.timestamp, //时间戳，自1970年以来的秒数
        nonceStr: res.data.nonce, //随机串
        package: "prepay_id=" + res.data.prepayId,
        signType: "RSA", //微信签名方式：
        paySign: res.data.sign, //微信签名
      },
      function (res: any) {
        if (res.err_msg === "get_brand_wcpay_request:ok") {
          Toast.show({
            content: "购买成功",
            duration: 5000,
          });
        } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
          Toast.show({
            content: "购买已取消",
            duration: 5000,
          });
        } else {
          Toast.show({
            content: "购买失败",
            duration: 5000,
          });
        }
      }
    );
  };

  useEffect(() => {
    getWxOpenId({ code: authCode }).then((res: any) => {
      if (res.code === 0) {
        setOpenId(res.data.openid);
      } else {
        Toast.show(res.msg);
      }
    });
  }, [authCode]);

  useEffect(() => {
    fetchProductList(activeTab);

    return () => {
      console.log(88);
    };
  }, [activeTab]);

  const fetchProductList = (type: number) => {
    getSlothMdseList({ tab: type }).then((res: any) => {
      if (res.code === 0) {
        setList(res.data.list);
      } else {
        Toast.show(res.msg);
      }
    });
  };

  const payHandler = (index: number) => {
    console.info(index);
    const item: any = list[index];
    setActiveIndex(index);
    slothOrderApplyJsapi(
      {
        slothId: slothId,
        amount: item.price,
        clientIp: "",
        mdseId: item.mdseId,
        mdseNum: 1,
        mdseType: item.mdseType,
        weixinOpenid: openId,
      }
    ).then((res: any) => {
      if (res.code === 0) {
        onBridgeReady(res);
      } else {
        Toast.show(res.msg);
      }
    });
  };
  return (
    <div className="h-full bg-offiaccount">
      <div className="px-1.67 pt-3.83 pb-1.67 bg-offiaccount text-offiaccount_black">
        {(
          <>
            <div className="mt-1 mb-3">
              <label htmlFor="slothId" className="mr-2 text-offiaccount_black style={{ fontSize: '30px' }}">
                <span className="text-red">*</span>树懒ID：
              </label>
              <input type="text" id="slothId" className="p-2" value={slothId} onChange={handleSlothIdChange}
                required placeholder="输入你的树懒ID" style={{
                  backgroundColor: '#F2F2F2',
                  color: '#221F30',
                  width: '250px',
                  height: '30px',
                  borderRadius: '12px',
                  border: '2px solid #694BFF'
                }} />
            </div>
            <div className="tabs flex justify-center mb-0.83">
              {TABS.map((tab) => (
                <div
                  key={tab.type}
                  className={`tab-item ${activeTab === tab.type ? "active" : ""} mx-1`}
                  onClick={() => setActiveTab(tab.type)}
                >
                  <h2 className={`text-2xl ${activeTab === tab.type ? "tab-active" : "tab-inactive"}`}>{tab.label}</h2>
                </div>
              ))}
            </div>

            <div className="flex flex-wrap justify-center gap-4">
              {list.map((item: any, index) => {
                return (
                  <div
                    key={index}
                    className={`item ${index === activeIndex ? "active" : ""} p-1 m-1`}
                    onClick={() => payHandler(index)}
                  >
                    {item.name} {item.price / 100}元
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default App;
