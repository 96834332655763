import React, { useEffect, useState } from "react";
import { checkPointsArrayEqual } from "../../utils/utils";
import { Toast } from "antd-mobile";

interface IProps {
  inputImage: any;
  preview: string;
  positivePoints: { x: number; y: number }[];
  negativePoints: { x: number; y: number }[];
  positivePointsTemp: { x: number; y: number }[];
  negativePointsTemp: { x: number; y: number }[];
  currentPen: string;
  setPositivePoints: Function;
  setNegativePoints: Function;
  setMaskBtnDisable: Function;
}

const POINT_WIDTH = 20;
const pointStyle: React.CSSProperties = {
  position: "absolute",
  cursor: "pointer",
  width: POINT_WIDTH + "px",
  height: POINT_WIDTH + "px",
  borderRadius: "50%",
};
const Preview: React.FC<IProps> = (props) => {
  const {
    inputImage,
    preview,
    positivePoints,
    negativePoints,
    positivePointsTemp,
    negativePointsTemp,
    currentPen,
    setPositivePoints,
    setNegativePoints,
    setMaskBtnDisable,
  } = props;
  const [scale, setScale] = useState(1);
  const [zIndex, setZIndex] = useState(0);

  const [widthFull, setWidthFull] = useState(false);
  const [heightFull, setHeightFull] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const handleImageClick = (event: {
    nativeEvent: { offsetX: number; offsetY: number };
  }) => {
    const { offsetX, offsetY } = event.nativeEvent;

    if (!loaded) {
      Toast.show("图片载入中，再试一次～");
      return false;
    }

    const newPoints =
      currentPen === "positive"
        ? [...positivePoints, { x: offsetX * scale, y: offsetY * scale }]
        : [...negativePoints, { x: offsetX * scale, y: offsetY * scale }];
    currentPen === "positive"
      ? setPositivePoints(newPoints)
      : setNegativePoints(newPoints);
  };
  useEffect(() => {
    setMaskBtnDisable(
      checkPointsArrayEqual(
        positivePoints,
        negativePoints,
        positivePointsTemp,
        negativePointsTemp
      )
    );
  }, [positivePoints, negativePoints]);

  const handleDotClick = (point: { x: number; y: number }, type: string) => {
    if (type === "positive") {
      // 黑点
      const newPositivePoints = positivePoints.filter((pos) => pos !== point);
      setPositivePoints(newPositivePoints);
    } else if (type === "negative") {
      // 如果当前为红点
      const newNegativePoints = negativePoints.filter((pos) => pos !== point);
      setNegativePoints(newNegativePoints);
    }
  };

  const onloadHandler = (event: any) => {
    const isWidthLong = event.target.naturalWidth / event.target.naturalHeight;
    const isConWidthLong = window.innerWidth / 395;

    console.info(event);

    if (isWidthLong > isConWidthLong) {
      setWidthFull(true);
    } else {
      setHeightFull(true);
    }

    setTimeout(() => {
      setScale(event.target.naturalHeight / event.target.offsetHeight);
      setLoaded(true);
    }, 1000);
  };
  const handleTouchStart = () => {
    setZIndex(11);
  };

  const handleTouchEnd = () => {
    setZIndex(0);
  };
  return (
    <div className="h-395 bg-bg flex justify-center items-center relative">
      <div
        className={`relative flex justify-center items-center ${
          heightFull ? "h-395" : ""
        }`}
      >
        <img
          src={inputImage}
          className="relative"
          onLoad={onloadHandler}
          onClick={handleImageClick}
          onContextMenu={(e: any) => {
            e.preventDefault();
          }}
          style={{
            zIndex,
            height: heightFull ? "inherit" : "initial",
          }}
          width={widthFull ? "100%" : "auto"}
          height={heightFull ? "100%" : "auto"}
        />
        {preview && (
          <img
            src={preview}
            onClick={handleImageClick}
            onContextMenu={(e: any) => {
              e.preventDefault();
            }}
            className=" absolute top-0 left-0 object-contain h-full w-full"
          />
        )}
        {positivePoints.map((point, index) => (
          <div
            key={index}
            style={{
              ...pointStyle,
              backgroundColor: "black",
              left: `${point.x / scale - POINT_WIDTH / 2}px`,
              top: `${point.y / scale - POINT_WIDTH / 2}px`,
            }}
            onClick={() => handleDotClick(point, "positive")}
          />
        ))}
        {negativePoints.map((point, index) => (
          <div
            key={index}
            style={{
              ...pointStyle,
              backgroundColor: "red",
              left: `${point.x / scale - POINT_WIDTH / 2}px`,
              top: `${point.y / scale - POINT_WIDTH / 2}px`,
            }}
            onClick={() => handleDotClick(point, "negative")}
          />
        ))}
      </div>
      {preview && (
        <div
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          className="absolute w-3 h-3 right-1.33 bottom-1.33 bg-black bg-opacity-20 flex justify-center items-center rounded-0.42"
        >
          <div className="bg-[url('../assets/images/change/switch.png')] w-2 h-2 bg-contain"></div>
        </div>
      )}
    </div>
  );
};

export default Preview;
