import React from "react";
import { getBgColor, getTextColor } from "../../utils/utils";
import { Slider } from "antd-mobile";

const MaskList = [
  {
    title: "快速选择",
    img: "bg-[url('../assets/images/changeV2/0.png')]",
    imgActive: "bg-[url('../assets/images/changeV2/00.png')]",
    type: "select",
  },
  {
    title: "笔刷",
    img: "bg-[url('../assets/images/changeV2/1.png')]",
    imgActive: "bg-[url('../assets/images/changeV2/11.png')]",
    type: "draw",
  },
  {
    title: "橡皮擦",
    img: "bg-[url('../assets/images/changeV2/2.png')]",
    imgActive: "bg-[url('../assets/images/changeV2/22.png')]",
    type: "clear",
  },
];

interface IProps {
  paintType: string;
  setPaintType: Function;
  paintNum: number;
  setPaintNum: Function;
}

const PaintBox: React.FC<IProps> = (props) => {
  const { paintType, setPaintType, paintNum, setPaintNum } = props;

  return (
    <div>
      <div className="text-34 text-1.33  mb-1.33  flex items-center font-bold">
        <div className="icon bg-[url('../assets/images/icon/5.png')]"></div>
        选择替换区域
        <span className="text-red text-251">*</span>
      </div>
      <div className="rounded-1.67 border-0.08 border-242 py-1.33 mb-1.67 flex flex-col">
        {paintType !== "select" && (
          <div className="pb-1.33 flex justify-between items-center mx-1.83 mb-1.33 border-b-0.08 border-242">
            <div
              className="bg-[url('../assets/images/changeV2/s.png')] w-2 h-2 bg-contain"
              onClick={() => {
                setPaintNum(paintNum - 10 < 0 ? 0 : paintNum - 10);
              }}
            ></div>
            <div className="flex-1">
              <Slider
                className="paint-slider"
                value={paintNum}
                min={1}
                onChange={(value) => setPaintNum(value)}
                icon={<div></div>}
                onAfterChange={(value) => setPaintNum(value)}
              />
            </div>
            <div
              className="bg-[url('../assets/images/changeV2/b.png')] w-2 h-2 bg-contain"
              onClick={() => {
                setPaintNum(paintNum + 10 > 100 ? 100 : paintNum + 10);
              }}
            ></div>
          </div>
        )}
        <div className="flex items-center justify-between px-2.92 self-stretch">
          {MaskList.map((item, index) => {
            return (
              <div
                key={index}
                className="flex flex-col items-center"
                onClick={() => {
                  setPaintType(item.type);
                }}
              >
                <div
                  className={`w-4.67 h-4.67 flex justify-center items-center rounded-2.33 mb-0.42 ${
                    paintType === item.type ? getBgColor() : "bg-bg"
                  }`}
                >
                  <div
                    className={`${
                      paintType === item.type ? item.imgActive : item.img
                    }  bg-contain w-2.5 h-2.5`}
                  ></div>
                </div>
                <div
                  className={`text-1 ${
                    paintType === item.type ? getTextColor() : "text-201"
                  }`}
                >
                  {item.title}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PaintBox;
