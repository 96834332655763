import React, { useEffect, useState } from "react";
import DialogOpenMember from "../components/DialogOpenMember";
import { Dialog, Toast } from "antd-mobile";
import { action, detail, remove } from "../service/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  commonInterface,
  getDownloadImage,
  getReDrawImage,
  isAndroid,
  isShowInput,
  isVip,
  isWechat,
  isWeibo,
} from "../utils/utils";
import "../assets/draw.css";
import {
  collect,
  collect1,
  collect11,
  collect2,
  collect22,
  like,
  like1,
  like11,
  like2,
  like22,
} from "../service/config";

const LIKE_ROTATE = ["rotate(-30deg)", "rotate(0)", "rotate(30deg)"];
let clickTimer: any = null;
let likeTimer: any = null;

const App: React.FC = () => {
  const navigate = useNavigate();
  const [isShowMember, setIsShowMember] = useState(false);
  const [isShowBigImage, setIsShowBigImage] = useState(false);
  const [isShowWeChatTips, setIsShowWeChatTips] = useState(false);
  const [likeStyle, setLikeStyle] = useState({
    opacity: 0,
    left: -60,
    top: -60,
    transform: "-30deg",
    zIndex: -1,
    transition: "1s all",
  });
  const [params] = useSearchParams();
  const detailId = params.get("detail_id");
  const share: any = params.get("share");
  const from: any = params.get("from");
  const [data, setData] = useState({
    prompt: "",
    style: { name: "", icon: "" },
    user: { nickname: "" },
    aspectRatio: 0,
    inputImage: "",
    quality: 0,
    relevancy: 0,
    likeBannedText: "",
    downloadBannedText: "",
    reportBanned: false,
    liked: false,
    content:
      '{"type":2,"images":[{"url":"","originSize":0,"width":0,"height":0,"duration":0}]}',
  });

  useEffect(() => {
    detail({ id: detailId, from: from ? 1 : 2 }).then((res: any) => {
      if (res.code === 0) {
        setData(res.data);
      } else {
        Toast.show(res.msg);
      }
    });
  }, []);

  const downloadHandler = async (event: any) => {
    event.stopPropagation();

    if (data.reportBanned) {
      Toast.show(data.downloadBannedText);
      return;
    }

    const vip = await isVip();
    action({
      actionName: "download",
      graphId: detailId,
    }).then(() => {});
    if (vip) {
      commonInterface("downloadImage", {
        url: JSON.parse(data.content).images[0].url,
      });
    } else {
      setIsShowMember(true);
    }
  };

  const deleteHandler = async () => {
    const result = await Dialog.confirm({
      bodyClassName: "delete-dialog",
      title: "提示",
      content: "确认删除该画作？",
    });
    if (result) {
      remove({ id: detailId }).then((res: any) => {
        if (res.code === 0) {
          commonInterface("refreshImageHistory");

          commonInterface("closePage");
        } else {
          Toast.show(res.msg);
        }
      });
    }
  };
  const likeHandler = (type: boolean, event?: any) => {
    event.stopPropagation();
    if (data.likeBannedText) {
      Toast.show(data.likeBannedText);
      return;
    }
    if (type) {
      action({
        graphId: detailId,
        actionName: "like",
      }).then((res: any) => {
        if (res.code === 0) {
          const rotate = LIKE_ROTATE[(Math.random() * 3) >> 0];
          setLikeStyle({
            opacity: 1,
            left: event.clientX - 40,
            top: event.clientY - 40,
            transform: rotate,
            zIndex: 100,
            transition: "0s all",
          });
          setData({
            ...data,
            liked: true,
          });

          if (likeTimer) {
            clearTimeout(likeTimer);
          }
          likeTimer = setTimeout(() => {
            setLikeStyle({
              ...likeStyle,
              left: event.clientX - 40,
              top: event.clientY - 40,
              opacity: 0,
              transform: rotate,
              zIndex: -1,
              transition: "0.2s all",
            });
          }, 1000);
          commonInterface("refreshImageHistory");
        }
      });
    } else {
      if (data.liked) {
        action({
          graphId: detailId,
          actionName: "cancel_like",
        }).then((res: any) => {
          if (res.code === 0) {
            setData({
              ...data,
              liked: false,
            });
            commonInterface("refreshImageHistory");
          }
        });
      } else {
        action({
          graphId: detailId,
          actionName: "like",
        }).then((res: any) => {
          if (res.code === 0) {
            setData({
              ...data,
              liked: true,
            });
            commonInterface("refreshImageHistory");
          }
        });
      }
    }
  };
  const getRatio = (index: number) => {
    if (index === 1) {
      return (
        <>
          <div className="ratio-icon w-3.33 h-3.33"></div>
          正方形
        </>
      );
    } else if (index === 3) {
      return (
        <>
          <div className="ratio-icon w-2 h-3.33"></div>
          竖图
        </>
      );
    } else if (index === 2) {
      return (
        <>
          <div className="ratio-icon w-3.33 h-2"></div>
          横图
        </>
      );
    }
  };
  const showBigImage = () => {
    if (share) {
      if (isWechat() || isWeibo()) {
        setIsShowWeChatTips(true);
      } else {
        const scheme =
          "with://com.with.fm/app/h5?url=" +
          encodeURIComponent(
            window.location.origin +
              "/draw?isFullScreen=true&share=1&detail_id=" +
              detailId
          );

        jumpApp(scheme);
        setTimeout(() => {
          window.location.href = `https://h5.hwith.cn/`;
        }, 2000);
      }
    } else {
      setIsShowBigImage(true);
    }
  };
  const jumpApp = (scheme: string) => {
    try {
      const e = navigator.userAgent.toLowerCase();
      let n: any = e.match(/cpu iphone os (.*?) like mac os/);
      if (((n = n !== null ? n[1].replace(/_/g, ".") : 0), parseInt(n) >= 9)) {
        window.location.href = scheme;
      } else {
        const r = document.createElement("iframe");
        r.src = scheme;
        r.style.display = "none";
        document.body.appendChild(r);
      }
    } catch (e) {
      window.location.href = scheme;
    }
  };
  const reportHandler = () => {
    action({
      actionName: "report",
      graphId: detailId,
    }).then((res: any) => {
      if (res.code === 0) {
        Toast.show({
          duration: 0,
          maskClickable: false,
          content: "图片举报成功，感谢你的反馈",
        });
        commonInterface("refreshImageHistory");
        setTimeout(() => {
          commonInterface("closePage");
        }, 3000);
      } else {
        Toast.show(res.msg);
      }
    });
  };
  const previewHandleClick = (event: any) => {
    if (!clickTimer) {
      clickTimer = setTimeout(function () {
        showBigImage();
        clickTimer = null;
      }, 300); // 设置延迟时间
    } else {
      clearTimeout(clickTimer);
      clickTimer = null;
      likeHandler(true, event);
    }
  };
  const previewHandleClick2 = (event: any) => {
    if (!clickTimer) {
      clickTimer = setTimeout(function () {
        setIsShowBigImage(false);
        clickTimer = null;
      }, 300); // 设置延迟时间
    } else {
      clearTimeout(clickTimer);
      clickTimer = null;
      likeHandler(true, event);
    }
  };
  return (
    <div className="bg-bg flex flex-col justify-between h-full">
      <div className="overflow-auto">
        <div className="relative">
          <img
            onClick={previewHandleClick}
            alt=""
            src={JSON.parse(data.content).images[0].url}
          />
        </div>
        <div className="p-1.33 pt-0.33">
          <div className="result-item">
            <div className="result-item-left">
              <span>描</span>
              <span>述</span>
              <span>词：</span>
            </div>
            <div className="flex-1 break-all">{data.prompt}</div>
          </div>
          {data.style ? (
            <div className="result-item">
              <div className="result-item-left">
                <span>选</span>
                <span>择</span>
                <span>风</span>
                <span>格：</span>
              </div>
              <div className="flex items-center">
                <img
                  alt=""
                  src={data.style.icon}
                  className="w-3.33 h-3.33 rounded-0.42 mr-0.33"
                />
                {data.style.name}
              </div>
            </div>
          ) : null}
          {data.aspectRatio !== 0 ? (
            <div className="result-item">
              <div className="result-item-left">
                <span>选</span>
                <span>择</span>
                <span>比</span>
                <span>例：</span>
              </div>
              <div className="flex items-center">
                {getRatio(data.aspectRatio)}
              </div>
            </div>
          ) : null}
          {data.inputImage && (
            <div className="result-item">
              <div className="result-item-left">
                <span>参</span>
                <span>考</span>
                <span>图：</span>
              </div>
              <div>
                <img
                  alt=""
                  src={data.inputImage}
                  className="w-3.33 h-3.33 rounded-0.42 mr-0.33"
                />
              </div>
            </div>
          )}
          {data.quality !== 0 ? (
            <div className="result-item">
              <div className="result-item-left">
                <span>质</span>
                <span>量：</span>
              </div>
              <div className="p-0.67 bg-bg rounded-0.42">
                {data.quality === 1 ? "标准" : "更多细节"}
              </div>
            </div>
          ) : null}
          {data.relevancy !== 0 ? (
            <div className="result-item">
              <div className="result-item-left">
                <span>相</span>
                <span>似</span>
                <span>度：</span>
              </div>
              <div className="p-0.67 bg-bg rounded-0.42">
                {data.relevancy === 1
                  ? "低"
                  : data.relevancy === 2
                  ? "中"
                  : "高"}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div
        className={
          "pt-1 " +
          (isAndroid ? "pb-12" : "pb-3.83") +
          " bg-white rounded-t-1.67 shadow-up z-40"
        }
      >
        {share ? (
          <div className="flex justify-around p-0.42 rounded-0.67">
            <div onClick={showBigImage} className={"btn-item"}>
              <img
                alt=""
                className="w-1.67 h-1.67 mr-0.33"
                src={require("../assets/images/draw.png")}
              />
              画同款
            </div>
          </div>
        ) : (
          <div className="flex justify-around p-0.42 rounded-0.67">
            <div
              onClick={() => {
                if (from) {
                  commonInterface("closePage");
                } else {
                  navigate("/draw?detail_id=" + detailId, { replace: true });
                }
              }}
              className={"btn-item"}
            >
              <img
                alt=""
                className="w-1.67 h-1.67 mr-0.33"
                src={require("../assets/images/" + getReDrawImage())}
              />
              重绘
            </div>
            <div className="w-0.08 text-266">|</div>
            <div onClick={() => deleteHandler()} className={"btn-item"}>
              <img
                alt=""
                className="w-1.67 h-1.67 mr-0.33"
                src={require("../assets/images/del.png")}
              />
              删除
            </div>
            <div className="w-0.08 text-266">|</div>
            <div
              onClick={(event) => downloadHandler(event)}
              className={"btn-item"}
            >
              <img
                alt=""
                className="w-1.67 h-1.67 mr-0.33"
                src={require("../assets/images/" + getDownloadImage())}
              />
              下载
            </div>
            <div className="w-0.08 text-266">|</div>
            <div
              onClick={(event) => likeHandler(false, event)}
              className={"btn-item"}
            >
              <img
                alt=""
                className="w-1.67 h-1.67 mr-0.33"
                src={
                  data.liked
                    ? isShowInput("collect")
                      ? collect2
                      : like2
                    : isShowInput("collect")
                    ? collect1
                    : like1
                }
              />
              {isShowInput("collect") ? "收藏" : "喜欢"}
            </div>
          </div>
        )}
      </div>
      {!share ? (
        <>
          <div
            className="absolute top-4.5 left-1.33 w-3 h-3 bg-[url('../assets/images/back.png')] bg-2 bg-center bg-no-repeat"
            onClick={() => {
              commonInterface("closePage");
            }}
          ></div>
          {!data.reportBanned ? (
            <div
              className="absolute top-4.5 right-1.33 w-3 h-3 bg-[url('../assets/images/report.png')] bg-2 bg-center bg-no-repeat"
              onClick={reportHandler}
            ></div>
          ) : null}
        </>
      ) : null}
      <DialogOpenMember
        isShow={isShowMember}
        hideDialog={() => setIsShowMember(false)}
      ></DialogOpenMember>
      {isShowBigImage ? (
        <div
          onClick={previewHandleClick2}
          className="absolute left-0 top-0 right-0 bottom-0 flex justify-center items-center bg-black z-50"
        >
          <img src={JSON.parse(data.content).images[0].url} />
          <div className="absolute bottom-4.5 right-88 left-88 flex justify-between">
            <div
              onClick={downloadHandler}
              className="bg-[url('../assets/images/download2.png')] w-5 h-5 bg-contain bg-no-repeat bg-100  "
            ></div>
            <div
              onClick={(event) => likeHandler(false, event)}
              className={`${
                data.liked
                  ? isShowInput("collect")
                    ? collect22
                    : like22
                  : isShowInput("collect")
                  ? collect11
                  : like11
              } w-5 h-5 bg-contain bg-no-repeat bg-100`}
            ></div>
          </div>
        </div>
      ) : null}
      {isShowWeChatTips ? (
        <div
          onClick={() => {
            setIsShowWeChatTips(false);
          }}
          className="fixed top-0 left-0 w-full h-full bg-black2 flex items-start justify-end"
        >
          <img
            width="60%"
            src={require("../assets/images/share_flower.webp")}
          />
        </div>
      ) : null}
      <img
        className="absolute w-80 h-80"
        style={likeStyle}
        src={isShowInput("collect") ? collect : like}
      />
    </div>
  );
};

export default App;
