import React, { useEffect, useState } from "react";
import DialogOpenMember from "../components/DialogOpenMember";
import { commonInterface, query, run, sleep } from "../utils/utils";
import { Dialog, Toast } from "antd-mobile";
import { useNavigate, useSearchParams } from "react-router-dom";
import "../assets/draw.css";
import Prompt from "../components/change/Prompt";
import FooterChange from "../components/change/FooterChange";
import Preview from "../components/change/Preview";
import Points from "../components/change/Points";
import {
  creatable,
  createGraphApi,
  detail,
  redrawSamples,
} from "../service/api";
import Style from "../components/change/Style";
import Loading from "../components/Loading";

let createId = 0;
const App: React.FC = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const detailId = params.get("detail_id");
  const inputImageUrl = params.get("inputImage") || "";
  const inputImageWidthUrl = params.get("inputImageWidth") || 0;
  const inputImageHeightUrl = params.get("inputImageHeight") || 0;
  const inputImageSizeUrl = params.get("inputImageSize") || 0;

  const from: any = params.get("from");
  const [showLoading, setShowLoading] = useState(false);
  const [isShowMember, setIsShowMember] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [inputImage, setInputImage] = useState(inputImageUrl);

  const [inputImageWidth, setInputImageWidth] = useState(inputImageWidthUrl);
  const [inputImageHeight, setInputImageHeight] = useState(inputImageHeightUrl);
  const [inputImageSize, setInputImageSize] = useState(inputImageSizeUrl);

  const [positivePoints, setPositivePoints] = useState<
    { x: number; y: number }[]
  >([]);
  const [negativePoints, setNegativePoints] = useState<
    { x: number; y: number }[]
  >([]);
  const [positivePointsTemp, setPositivePointsTemp] = useState<
    { x: number; y: number }[]
  >([]);
  const [negativePointsTemp, setNegativePointsTemp] = useState<
    { x: number; y: number }[]
  >([]);
  const [currentPen, setCurrentPen] = useState("positive");
  const [prompt, setPrompt] = useState("");
  const [mask, setMask] = useState("");
  const [preview, setPreview] = useState("");
  const [style, setStyle] = useState({ id: 0, name: "" });

  const [log, setLog] = useState("");
  const [tips, setTips] = useState([]);
  const [titles, setTitles] = useState([]);
  const [estimatedSeconds, setEstimatedSeconds] = useState(0);
  const [samples, setSamples] = useState<{ image: string }[]>([]);
  const [maskBtnDisable, setMaskBtnDisable] = useState(true);
  useEffect(() => {
    redrawSamples({ type: 5 }).then((res: any) => {
      if (res.code === 0 && res.data.list.length > 0) {
        setSamples(res.data.list);
      }
    });
    if (detailId) {
      detail({ id: detailId, from: from ? 1 : 2 }).then((res: any) => {
        if (res.code === 0) {
          setInputImage(res.data.inputImage);
          setInputImageWidth(res.data.inputImageWidth);
          setInputImageHeight(res.data.inputImageHeight);
          setInputImageSize(res.data.inputImageSize);
          setPositivePoints(res.data.positivePoints);
          setNegativePoints(res.data.negativePoints);
          setMask(res.data.mask);
          setPreview(res.data.maskPreview);
          setStyle(res.data.style);
          setPrompt(res.data.prompt);
        } else {
          Toast.show(res.msg);
        }
      });
    }
  }, []);

  const createHandler = async () => {
    setShowLoading(true);
    const createResult: any = await createGraphApi({
      inputImage,
      styleId: style.id,
      prompt,
      mask,
      maskPreview: preview,
      positivePoints,
      negativePoints,
      inputImageWidth,
      inputImageHeight,
      inputImageSize,
      type: 5,
    });
    console.info(createResult);
    if (createResult.code === 0) {
      console.info(createResult);
      createId = createResult.data.id;
      commonInterface("setDrawingId", {
        drawingId: createId,
      });
      await sleep(); // 3 ~ 10秒，之后在请求
      await firstQueryHandler();
    } else {
      setShowLoading(false);
      setBtnDisable(false);
      Toast.show(createResult.desc || createResult.msg);
    }
  };

  const firstQueryHandler = async () => {
    try {
      const responseFirst: any = await query(createId);

      if(responseFirst.code ===0 ) {
        setLoadingValue(responseFirst); //loading组件需要的文字
        await normalQueryHandler(); //正常轮训
      } else {
        Toast.show(responseFirst["msg"]);
        setShowLoading(false);
        setBtnDisable(false);
        commonInterface("setDrawingId", { drawingId: "" });
      }
    } catch (e) {
      console.info('responseFirst.catch',e)
      setShowLoading(false);
      setBtnDisable(false);
      commonInterface("setDrawingId", { drawingId: "" });
    }
  };
  const normalQueryHandler = async () => {
    const result = await run(createId);

    setLoadingValue(null);

    commonInterface("setDrawingId", { drawingId: "" });
    if (result["code"] === 0) {
      commonInterface("refreshImageHistory");
      const scheme = `with://com.with.fm/app/h5?url=${encodeURIComponent(
        window.location.origin +
          `/change_result?from=draw&isFullScreen=true&detail_id=${createId}`
      )}`;
      console.info("scheme", scheme);
      commonInterface("openScheme", { scheme });
      createId = 0;
    } else {
      Toast.show(result["msg"]);
    }
  };
  const check = async () => {
    if (prompt.trim() === "") {
      Toast.show("请输入描述词～");
      return false;
    }
    if (positivePoints.length === 0 && negativePoints.length === 0) {
      Toast.show("请您先在图片上标记想要替换的元素～");
      return false;
    }
    if (mask === "") {
      Toast.show("请先应用标记～");
      return false;
    }

    if (!maskBtnDisable) {
      const result = await Dialog.confirm({
        content: "当前标记未应用，是否继续？",
      });
      return result;
    }

    return true;
  };
  const setLoadingValue = (responseFirst: any) => {
    if (responseFirst) {
      if (responseFirst.data && responseFirst.data.titles) {
        setTitles(responseFirst.data.titles);
        setTips(responseFirst.data.tips);
        setEstimatedSeconds(responseFirst.data.estimatedSeconds);
      } else {
        console.info('太快了，没拿到tips')
      }
    } else {
      setTips([]);
      setShowLoading(false);
      setBtnDisable(false);
    }
  };
  const submitHandler = async () => {
    console.info(positivePoints);
    if (!(await check())) return;
    if (btnDisable) return;
    setBtnDisable(true);
    try {
      const isCan: any = await creatable({ type: 5 });
      if (isCan.code === 0 && isCan.data.creatable) {
        await createHandler();
      } else {
        //非会员
        setBtnDisable(false);
        setIsShowMember(true);
      }
    } catch (e) {
      //断网
      setBtnDisable(false);
    }
  };

  return (
    <div
      className={`flex flex-col justify-between h-full ${
        inputImage ? "bg-white " : "bg-bg pt-1.67"
      } `}
    >
      <div
        className="fixed top-4.5 left-1.33 bg-[url('../assets/images/change/re_select.png')] w-2 h-2 bg-contain z-100"
        onClick={() => {
          commonInterface("closePage");
        }}
      ></div>
      <Preview
        inputImage={inputImage}
        preview={preview}
        setPositivePoints={(value: { x: number; y: number }[]) =>
          setPositivePoints(value)
        }
        setNegativePoints={(value: { x: number; y: number }[]) =>
          setNegativePoints(value)
        }
        setMaskBtnDisable={(value: boolean) => setMaskBtnDisable(value)}
        positivePoints={positivePoints}
        negativePoints={negativePoints}
        positivePointsTemp={positivePointsTemp}
        negativePointsTemp={negativePointsTemp}
        currentPen={currentPen}
      />
      <div className="flex-1 p-1.33 overflow-auto pb-2.5">
        <Points
          currentPen={currentPen}
          setCurrentPen={(value: string) => setCurrentPen(value)}
          positivePoints={positivePoints}
          negativePoints={negativePoints}
          inputImage={inputImage}
          setMask={(value: string) => setMask(value)}
          setPreview={(value: string) => setPreview(value)}
          maskBtnDisable={maskBtnDisable}
          setMaskBtnDisable={(value: boolean) => setMaskBtnDisable(value)}
          setPositivePointsTemp={(value: { x: number; y: number }[]) =>
            setPositivePointsTemp(value)
          }
          setNegativePointsTemp={(value: { x: number; y: number }[]) =>
            setNegativePointsTemp(value)
          }
          clearPointsHandler={() => {
            setNegativePoints([]);
            setPositivePoints([]);
            setMask("");
            setPreview("");
          }}
        />
        <Prompt
          initValue={prompt}
          setValue={(value: string) => setPrompt(value)}
        />
        <Style initValue={style} setValue={(value: any) => setStyle(value)} />
      </div>
      <FooterChange disable={btnDisable} submitHandler={submitHandler} />

      <DialogOpenMember
        isShow={isShowMember}
        hideDialog={() => setIsShowMember(false)}
      ></DialogOpenMember>
      {showLoading && (
        <Loading
          log={log}
          tips={tips}
          titles={titles}
          isShow={showLoading}
          estimatedSeconds={estimatedSeconds}
        />
      )}
    </div>
  );
};

export default App;
