export const MASK_COLOR = { r: 0, g: 0, b: 255 };
export const getColorMap = (imageData: any) => {
  let colorMap: any = {};
  const pixels = imageData.data;
  for (var i = 0; i < pixels.length; i += 4) {
    var r = pixels[i];
    var g = pixels[i + 1];
    var b = pixels[i + 2];

    // 生成颜色值字符串
    var color = r + "-" + g + "-" + b;

    // 将当前像素点存入对应的颜色数组中
    if (!colorMap[color]) {
      colorMap[color] = [
        {
          x: (i / 4) % imageData.width,
          y: Math.floor(i / (4 * imageData.width)),
        },
      ];
    } else {
      colorMap[color].push({
        x: (i / 4) % imageData.width,
        y: Math.floor(i / (4 * imageData.width)),
      });
    }
  }
  return colorMap;
};
export const getPixelRGB = (
  x: number,
  y: number,
  ctx: CanvasRenderingContext2D
) => {
  const pixelColor = ctx?.getImageData(x, y, 1, 1).data;
  return {
    r: pixelColor[0],
    g: pixelColor[1],
    b: pixelColor[2],
    a: pixelColor[3],
  };
};
export const selectHandler = (
  paintCanvas: any,
  colorMap: any,
  event: any,
  ctx: any,
  paintCtx: any,
  type: "add_select" | "remove_select"
) => {
  // 获取当前像素点的颜色
  const pixelColor = getPixelRGB(event.offsetX, event.offsetY, ctx);
  if (paintCanvas.current) {
    if (paintCtx) {
      const pixels =
        colorMap[`${pixelColor.r}-${pixelColor.g}-${pixelColor.b}`];
      if (pixels) {
        fillOrClear(pixels, type, paintCtx);
      }
    }
  }
};

export const paintClick = (
  event: any,
  paintCanvas: any,
  ctx: any,
  paintType: any,
  colorMap: any
) => {
  if (paintType !== "select") return;
  const paintCtx = paintCanvas.current?.getContext("2d", {
    willReadFrequently: true,
  });
  if (paintCtx) {
    const pixelColor = getPixelRGB(event.offsetX, event.offsetY, paintCtx);
    if (
      pixelColor.r === MASK_COLOR.r &&
      pixelColor.g === MASK_COLOR.g &&
      pixelColor.b === MASK_COLOR.b
    ) {
      selectHandler(
        paintCanvas,
        colorMap,
        event,
        ctx,
        paintCtx,
        "remove_select"
      );
    } else {
      selectHandler(paintCanvas, colorMap, event, ctx, paintCtx, "add_select");
    }
  }
};

export const getMaskBase64 = (
  paintCanvas: any,
  maskCanvas: any,
  EXPAND: number
) => {
  console.info("EXPAND", EXPAND);
  const paintCtx = paintCanvas.current.getContext("2d", {
    willReadFrequently: true,
  });

  const maskCtx = maskCanvas.current.getContext("2d", {
    willReadFrequently: true,
  });
  maskCanvas.current.width = paintCanvas.current.width;
  maskCanvas.current.height = paintCanvas.current.height;

  if (paintCtx) {
    const imageData = paintCtx.getImageData(
      0,
      0,
      paintCanvas.current.width,
      paintCanvas.current.height
    );
    const pixels = imageData.data;
    let hasBluePixel = false; // 添加一个变量，用于记录是否存在蓝色像素

    for (let i = 0; i < pixels.length; i += 4) {
      const red = pixels[i];
      const green = pixels[i + 1];
      const blue = pixels[i + 2];
      const alpha = pixels[i + 3];

      // 检查像素颜色
      if (red === 0 && green === 0 && blue === 0 && alpha === 0) {
        // 将像素设置为黑色
        pixels[i] = 0;
        pixels[i + 1] = 0;
        pixels[i + 2] = 0;
        pixels[i + 3] = 255;
      } else if (red === 0 && green === 0 && blue === 255) {
        hasBluePixel = true; // 存在蓝色像素，将变量置为 true

        // 将蓝色像素设置为白色
        pixels[i] = 255;
        pixels[i + 1] = 255;
        pixels[i + 2] = 255;
        pixels[i + 3] = 255;

        for (let offsetY = -EXPAND; offsetY <= EXPAND; offsetY++) {
          for (let offsetX = -EXPAND; offsetX <= EXPAND; offsetX++) {
            const newIndex =
              i + (offsetY * paintCanvas.current.width + offsetX) * 4;

            // 检查像素点是否在画布范围内
            if (
              newIndex >= 0 &&
              newIndex < pixels.length &&
              pixels[newIndex] === 0 && // 检查扩展区域是否为黑色，如果不是黑色则跳过
              pixels[newIndex + 1] === 0 &&
              pixels[newIndex + 2] === 0 &&
              pixels[newIndex + 3] === 255
            ) {
              // 将扩展区域的像素点设置为白色
              pixels[newIndex] = 255;
              pixels[newIndex + 1] = 255;
              pixels[newIndex + 2] = 255;
              pixels[newIndex + 3] = 255;
            }
          }
        }
      }
    }

    // 将修改后的像素数据放回 Canvas
    maskCtx?.putImageData(imageData, 0, 0);

    // 检查是否存在蓝色像素，如果不存在则返回-1
    if (!hasBluePixel) {
      return false;
    }

    return maskCanvas.current.toDataURL();
  }
};

export const getOffsetXY = (
  event: any,
  scale: number,
  isWidthImage: boolean
) => {
  const touch = event.touches[0];
  const parentElement = event.target.getBoundingClientRect();
  const k = isWidthImage
    ? window.innerWidth / parentElement.width
    : 395 / parentElement.height;
  const offsetX = ((touch.pageX - parentElement.left) / scale) * k; // 计算触摸事件在父元素中的水平相对位置
  const offsetY = ((touch.pageY - parentElement.top) / scale) * k; // 计算触摸事件在父元素中的垂直相对位置

  return { offsetX, offsetY };
};
export const fillOrClear = (pixels: any, type: any, paintCtx: any) => {
  for (let i = 0; i < pixels.length; i++) {
    if (type === "add_select") {
      paintCtx.fillStyle = `rgba(${MASK_COLOR.r}, ${MASK_COLOR.g}, ${MASK_COLOR.b}, 0.5)`;
      paintCtx.fillRect(pixels[i].x, pixels[i].y, 1, 1);
    } else {
      paintCtx.clearRect(pixels[i].x, pixels[i].y, 1, 1);
    }
  }
};

export const drawOrClear = (
  paintNum: any,
  paintType: any,
  paintCtx: any,
  position: any
) => {
  if (paintType === "draw") {
    paintCtx.fillStyle = `rgba(${MASK_COLOR.r},${MASK_COLOR.g},${MASK_COLOR.b},1)`;
    paintCtx.beginPath();
    paintCtx.arc(
      position.offsetX, // 圆心 x 坐标
      position.offsetY, // 圆心 y 坐标
      paintNum / 2, // 半径
      0, // 起始角度
      Math.PI * 2 // 终止角度
    );
    paintCtx.closePath();
    paintCtx.fill();
  } else if (paintType === "clear") {
    paintCtx.save(); // 保存当前画布状态
    paintCtx.globalCompositeOperation = "destination-out"; // 设置混合模式为目标图像减去源图像
    paintCtx.beginPath();
    paintCtx.arc(
      position.offsetX, // 圆心 x 坐标
      position.offsetY, // 圆心 y 坐标
      paintNum / 2, // 半径
      0, // 起始角度
      Math.PI * 2 // 终止角度
    );
    paintCtx.closePath();
    paintCtx.fill();
    paintCtx.restore(); // 恢复之前保存的画布状态
  }
};

export const saveUndoStack = (
  paintCanvas: any,
  undoStack: any,
  setUndoStack: any,
  setRedoStack: any
) => {
  const paintCtx = paintCanvas.current?.getContext("2d", {
    willReadFrequently: true,
  });
  const state = paintCtx.getImageData(
    0,
    0,
    paintCanvas.current.width,
    paintCanvas.current.height
  );
  setUndoStack([...undoStack, state]);
  setRedoStack([]);
};

export const clearCanvas = (paintCanvas: any) => {
  const paintCtx = paintCanvas.current?.getContext("2d", {
    willReadFrequently: true,
  });

  // 清空画布
  paintCtx.clearRect(
    0,
    0,
    paintCanvas.current.width,
    paintCanvas.current.height
  );
};

export const undoHandler = (
  paintCanvas: any,
  undoStack: any,
  redoStack: any,
  setUndoStack: any,
  setRedoStack: any
) => {
  if (undoStack.length === 0) {
    return;
  }
  const paintCtx = paintCanvas.current?.getContext("2d", {
    willReadFrequently: true,
  });
  // 将当前状态保存到重做栈中
  const state = paintCtx.getImageData(
    0,
    0,
    paintCanvas.current.width,
    paintCanvas.current.height
  );
  setRedoStack([...redoStack, state]);
  clearCanvas(paintCanvas);
  // 恢复上一次保存的画布状态
  setUndoStack((prevUndoStack: any) => {
    const newUndoStack = prevUndoStack.slice(0, -1);
    if (newUndoStack.length > 0) {
      paintCtx.putImageData(newUndoStack[newUndoStack.length - 1], 0, 0);
    }
    return newUndoStack;
  });
};
export const redoHandler = (
  paintCanvas: any,
  undoStack: any,
  redoStack: any,
  setUndoStack: any
) => {
  if (redoStack.length === 0) {
    return;
  }
  const paintCtx = paintCanvas.current?.getContext("2d", {
    willReadFrequently: true,
  });

  // 清空画布
  clearCanvas(paintCanvas);
  // 恢复上一次保存的画布状态
  const state = redoStack.pop();
  setUndoStack([...undoStack, state]);
  paintCtx.putImageData(state, 0, 0);
};

export const blueToHalfAlpha = (paintCanvas:any) => {
  const paintCtx = paintCanvas.current?.getContext("2d");
  if (!paintCtx) return;
  const imageData = paintCtx.getImageData(0, 0, paintCanvas.current.width, paintCanvas.current.height);
  for (let i = 0; i < imageData.data.length; i += 4) {
    const r = imageData.data[i];
    const g = imageData.data[i + 1];
    const b = imageData.data[i + 2];
    if (r === 0 && g === 0 && b === 255) {
      imageData.data[i + 3] = 128; // 将透明度设置为0.5
    }
  }

  // 将修改后的像素数据重新绘制到画布上
  paintCtx.putImageData(imageData, 0, 0);
}