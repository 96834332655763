import React, { useState } from "react";
import {
  commonInterface,
  getBgColor,
  isAndroid,
  isCanCreate,
} from "../../utils/utils";
import { isInApp, nativeUploadHandler } from "../../utils/drawUtils";
import { Upload } from "../../service/upload";
import DialogOpenMember from "../DialogOpenMember";
import {creatable} from "../../service/api";

interface IProps {}

const FooterUploadImage: React.FC<IProps> = (props) => {
  const {} = props;
  const [disable, setDisable] = useState(false);
  const [isShowMember, setIsShowMember] = useState(false);
  const uploadImage = async (event: any) => {
    const file: any = event.target.files[0];
    const res: any = await Upload(file);

    window.location.href = `/change_form?from=draw&isFullScreen=true&inputImage=${
        res.url
    }&inputImageWidth=${101}&inputImageHeight=${101}&inputImageSize=${9059}`;
  };
  window.uploadImageResult = async (
      url: string,
      width: number,
      height: number,
      size: number
  ) => {
    try {
      const isCan: any = await creatable({ type: 5 });
      if (isCan.code === 0 && isCan.data.creatable) {
        const scheme = `with://com.with.fm/app/h5?url=${encodeURIComponent(
            window.location.origin +
            `/change_form?from=draw&isFullScreen=true&inputImage=${url}&inputImageWidth=${width}&inputImageHeight=${height}&inputImageSize=${size}`
        )}`;
        console.info("scheme", scheme);
        commonInterface("openScheme", { scheme });
      } else {
        //非会员

        setIsShowMember(true);
      }
    } catch (e) {
      //断网
    }
  };
  return (
      <div
          className={
              "pt-1 px-1.33 " +
              (isAndroid ? "pb-12" : "pb-3.83") +
              " bg-white rounded-t-1.67 shadow-up " +
              (disable ? "opacity-50" : "")
          }
          onClick={nativeUploadHandler}
      >
        <div
            className={
                "relative rounded-2.33 p-1.17 text-1.67 text-center text-white " +
                getBgColor()
            }
        >
          上传图片
          {isInApp() ? null : (
              <input
                  type="file"
                  className="opacity-0 left-0 top-0 right-0 bottom-0 absolute"
                  id="input"
                  onChange={uploadImage}
              ></input>
          )}
        </div>
        <DialogOpenMember
            isShow={isShowMember}
            hideDialog={() => setIsShowMember(false)}
        ></DialogOpenMember>
      </div>
  );
};

export default FooterUploadImage;
