import React, { useEffect, useState } from "react";
import DialogOpenMember from "../components/DialogOpenMember";
import { Dialog, Toast } from "antd-mobile";
import { detail, remove, action } from "../service/api";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  commonInterface,
  getDownloadImage,
  getReDrawImage,
  isAndroid,
  isVip,
} from "../utils/utils";

const App: React.FC = () => {
  const navigate = useNavigate();
  const [isShowMember, setIsShowMember] = useState(false);
  const [isShowBigImage, setIsShowBigImage] = useState(false);
  const [isShowWeChatTips, setIsShowWeChatTips] = useState(false);

  const [params] = useSearchParams();
  const detailId = params.get("detail_id");
  const from: any = params.get("from");
  const [data, setData] = useState({
    inputImage: "",
    downloadBannedText: "",
    reportBanned: false,
    content:
      '{"type":2,"images":[{"url":"","originSize":0,"width":0,"height":0,"duration":0}]}',
  });

  useEffect(() => {
    detail({ id: detailId, from: from ? 1 : 2 }).then((res: any) => {
      if (res.code === 0) {
        setData(res.data);
      } else {
        Toast.show(res.msg);
      }
    });
  }, []);

  const downloadHandler = async (event: any) => {
    event.stopPropagation();

    if (data.reportBanned) {
      Toast.show(data.downloadBannedText);
      return;
    }

    const vip = await isVip();
    action({
      actionName: "download",
      graphId: detailId,
    }).then(() => {});
    if (vip) {
      commonInterface("downloadImage", {
        url: JSON.parse(data.content).images[0].url,
      });
    } else {
      setIsShowMember(true);
    }
  };

  const deleteHandler = async () => {
    const result = await Dialog.confirm({
      bodyClassName: "delete-dialog",
      title: "提示",
      content: "确认删除该头像？",
    });
    if (result) {
      remove({ id: detailId }).then((res: any) => {
        if (res.code === 0) {
          commonInterface("refreshImageHistory");

          commonInterface("closePage");
        } else {
          Toast.show(res.msg);
        }
      });
    }
  };

  const showBigImage = () => {
    setIsShowBigImage(true);
  };

  const reportHandler = () => {
    action({
      actionName: "report",
      graphId: detailId,
    }).then((res: any) => {
      if (res.code === 0) {
        Toast.show({
          duration: 0,
          maskClickable: false,
          content: "图片举报成功，感谢你的反馈",
        });
        commonInterface("refreshImageHistory");
        setTimeout(() => {
          commonInterface("closePage");
        }, 3000);
      } else {
        Toast.show(res.msg);
      }
    });
  };

  return (
    <div className="bg-bg flex flex-col justify-between h-full">
      <div className="overflow-auto flex flex-col justify-center items-center flex-1 ">
        <img
          onClick={showBigImage}
          alt=""
          className="w-25.42 h-25.42 rounded-2.5 photo-result-shadow"
          src={JSON.parse(data.content).images[0].url}
        />
      </div>
      <div
        className={
          "pt-1 " +
          (isAndroid ? "pb-12" : "pb-3.83") +
          " bg-white rounded-t-1.67 shadow-up z-40"
        }
      >
        <div className="flex justify-around p-0.42 rounded-0.67">
          {from ? <>
            <div
                onClick={() => {
                  if (from) {
                    commonInterface("closePage");
                  } else {
                    navigate("/photo?detail_id=" + detailId, {replace: true});
                  }
                }}
                className={"btn-item"}
            >
              <img
                  alt=""
                  className="w-1.67 h-1.67 mr-0.33"
                  src={require("../assets/images/" + getReDrawImage())}
              />
              重绘
            </div>
            <div className="w-0.08 text-266">|</div>
          </>:null
          }
          <div onClick={() => deleteHandler()} className={"btn-item"}>
            <img
              alt=""
              className="w-1.67 h-1.67 mr-0.33"
              src={require("../assets/images/del.png")}
            />
            删除
          </div>
          <div className="w-0.08 text-266">|</div>
          <div
            onClick={(event) => downloadHandler(event)}
            className={"btn-item"}
          >
            <img
              alt=""
              className="w-1.67 h-1.67 mr-0.33"
              src={require("../assets/images/" + getDownloadImage())}
            />
            下载
          </div>
        </div>
      </div>

      <>
        <div
          className="absolute top-4.5 left-1.33 w-3 h-3 bg-[url('../assets/images/back_3.png')] bg-2 bg-center bg-no-repeat"
          onClick={() => {
            commonInterface("closePage");
          }}
        ></div>
        {!data.reportBanned ? (
          <div
            className="absolute top-4.5 right-1.33 w-3 h-3 bg-[url('../assets/images/report2.png')] bg-2 bg-center bg-no-repeat"
            onClick={reportHandler}
          ></div>
        ) : null}
      </>

      <DialogOpenMember
        isShow={isShowMember}
        hideDialog={() => setIsShowMember(false)}
      ></DialogOpenMember>
      {isShowBigImage ? (
        <div
          onClick={() => setIsShowBigImage(false)}
          className="absolute left-0 top-0 right-0 bottom-0 flex justify-center items-center bg-black z-50"
        >
          <img src={JSON.parse(data.content).images[0].url} />
          <div
            onClick={downloadHandler}
            className="absolute bg-[url('../assets/images/download2.png')] w-5 h-5 bg-contain bg-no-repeat bg-100 bottom-4.5 right-1.33"
          ></div>
        </div>
      ) : null}
      {isShowWeChatTips ? (
        <div
          onClick={() => {
            setIsShowWeChatTips(false);
          }}
          className="fixed top-0 left-0 w-full h-full bg-black2 flex items-start justify-end"
        >
          <img
            width="60%"
            src={require("../assets/images/share_flower.webp")}
          />
        </div>
      ) : null}
    </div>
  );
};

export default App;
