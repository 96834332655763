import React, { useState } from "react";
import {
  commonInterface,
  getBgColor,
  isAndroid,
  isCanCreate,
} from "../../utils/utils";
import { isInApp, nativeUploadHandler } from "../../utils/drawUtils";
import { Upload } from "../../service/upload";
import DialogOpenMember from "../DialogOpenMember";
import { predictAuto } from "../../service/api";
import { Toast } from "antd-mobile";

interface IProps {}

const inputImageUrl =
  "https://with-live.oss-cn-beijing.aliyuncs.com/h5/ai/7923587c06524e5c8f4be4164211d2fd.jpeg";
const colorImage =
  "https://with-live.oss-cn-beijing.aliyuncs.com/h5/ai/60861bb44ffb4c9ca24c25bf6ad0b578.jpeg";
const FooterUploadImage: React.FC<IProps> = (props) => {
  const {} = props;
  const [disable, setDisable] = useState(false);
  const [isShowMember, setIsShowMember] = useState(false);
  const uploadImage = async (event: any) => {
    const file: any = event.target.files[0];
    const response: any = await Upload(file);
    Toast.show({
      icon: "loading",
      duration: 0,
      maskClickable: false,
      content: "图片分区智能识别中...",
    });
    predictAuto({ inputImage: response.url }).then((res: any) => {
      if (res.code === 0) {
        Toast.clear();
        window.location.href = `/change_form_v2?from=draw&isFullScreen=true&inputImage=${
          response.url
        }&colorImage=${
          res.data.mask
        }&inputImageWidth=${1389}&inputImageHeight=${1025}&inputImageSize=${390469}`;
      }
    });
  };
  window.uploadImageResult = async (
    url: string,
    width: number,
    height: number,
    size: number
  ) => {
    try {
      const isCan = await isCanCreate();
      if (isCan) {
        Toast.show({
          icon: "loading",
          duration: 0,
          maskClickable: false,
          content: "图片分区智能识别中...",
        });
        predictAuto({ inputImage: url }).then((res: any) => {
          Toast.clear();
          if (res.code === 0) {
            const scheme =
              `with://com.with.fm/app/h5?url=` +
              encodeURIComponent(
                `${
                  window.location.origin
                }/change_form_v2?from=draw&isFullScreen=true&inputImage=${encodeURIComponent(
                  url
                )}&colorImage=${encodeURIComponent(
                  res.data.mask
                )}&inputImageWidth=${width}&inputImageHeight=${height}&inputImageSize=${size}`
              );
            console.info("scheme", scheme);
            commonInterface("openScheme", { scheme });
          } else {
            Toast.show(res.desc);
          }
        });
      } else {
        //非会员
        setIsShowMember(true);
      }
    } catch (e) {
      //断网
    }
  };
  return (
    <div
      className={
        "pt-1 px-1.33 " +
        (isAndroid ? "pb-12" : "pb-3.83") +
        " bg-white rounded-t-1.67 shadow-up " +
        (disable ? "opacity-50" : "")
      }
      onClick={nativeUploadHandler}
    >
      <div
        className={
          "relative rounded-2.33 p-1.17 text-1.67 text-center text-white " +
          getBgColor()
        }
      >
        上传图片
        {isInApp() ? null : (
          <input
            type="file"
            className="opacity-0 left-0 top-0 right-0 bottom-0 absolute"
            id="input"
            onChange={uploadImage}
          ></input>
        )}
      </div>
      <DialogOpenMember
        isShow={isShowMember}
        hideDialog={() => setIsShowMember(false)}
      ></DialogOpenMember>
    </div>
  );
};

export default FooterUploadImage;
